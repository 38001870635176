// start of core.scss

.slicknav_btn {
  position: relative;
  display: block;
  vertical-align: middle;
  float: right;
  padding: 0.438em 0.625em 0.438em 0.625em;
  line-height: 1.125em;
  cursor: pointer;
  .slicknav_icon-bar + .slicknav_icon-bar {
    margin-top: 0.188em;
  }
}

.slicknav_menu {
  *zoom: 1;
  .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left;
  }
  .slicknav_icon {
    float: left;
    width: 1.125em;
    height: 0.875em;
    margin: 0.188em 0 0 0.438em;

    &:before { // Firefox Mobile v42 bugfix
      background: transparent;
      width: 1.125em;
      height: 0.875em;
      display: block;
      content: "";
      position: absolute;
    }
  }
  .slicknav_no-text {
    margin: 0;
  }
  .slicknav_icon-bar {
    display: block;
    width: 1.125em;
    height: 0.125em;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  }
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}
.slicknav_nav {
  clear: both;
  ul {
    display: block;
  }
  li {
    display: block;
  }
  .slicknav_arrow {
    font-size: 0.8em;
    margin: 0 0 0 0.4em;
  }
  .slicknav_item {
    cursor: pointer;
    a {
      display: inline;
    }
  }
  .slicknav_row {
    display: block;
  }
  a {
    display: block;
  }
  .slicknav_parent-link a {
    display: inline;
  }
}
.slicknav_brand {
  float: left;
}

// end of slicknav scss styles core, copy paste scss if it changes here

.slicknav_menu {
  .slicknav_nav {
    padding-top: 20px;
  }
}

.slicknav_open {
  .slicknav_menutxt {
    &:after {
      display: inline-block;
      content: "";
      @include sprite($sprite-menu-close);
      position: relative;
      top: 2px;
    }
  }
}

.slicknav_collapsed {
  .slicknav_menutxt {
    &:after {
      display: inline-block;
      content: "";
      @include sprite($sprite-menu-open);
      position: relative;
      top: 2px;
    }
  }
}

.slicknav_menu {
  clear: both;
  background: $white;

  a {
    color: $black;
    text-decoration: none;
  }

  .slicknav_btn {
    position: static;
    display: block;
    vertical-align: middle;
    float: none;
    padding: 0;
    line-height: 0;
    cursor: pointer;

    .slicknav_icon-bar + .slicknav_icon-bar {
      margin-top: 0;
    }

    .slicknav_icon {
      float: none;
      display: none;
    }
  }

  .slicknav_nav {
   /* margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;*/
    height: calc(100vh - 55px);
    overflow-y: auto;
    position: relative;

    .activew-circle {
      right: 50%;
      top: 18px;
      transform: translate(58px);
    }

    .slicknav_arrow {
      color: $black;
      margin-left: 0.1em;
      font-size: 16px;
    }

    li {
      text-align: center;

      a, span.nolink {
       // display: block;
        display: inline-block;
        color: $black;
        position: relative;
        padding: 12px 0;
      //  font-size: $larger;
        font-size: 1.11111rem;
        text-align: center;
        font-weight: 400;
      }

      &.slicknav_parent {
        > a {
          padding-left: 0;
        }
      }

      .slicknav_arrow {
        position: absolute;
        top: 0;
        right: 0;
        height: 36px;
        width: 56px;
        display: block;
        line-height: 36px;
        vertical-align: middle;
        text-align: center;
      }
    }
  }

  .slicknav_nav .sf-hidden li{
    a{
      padding-left: 30px;
    }
  }


  .slicknav_menutxt {
    display: block;
    float: none;
    font-size: 0.88888rem;
    // float: right;
    /*     margin-right: 0.8em;
        margin-bottom: -0.2em; */
    vertical-align: text-bottom;
    padding: 16px 0 16px 18px;
    width: 90px;
    text-align: right;
    line-height: 1.4;
    background: none;
    color: $black;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0px;

    .txt-layer {
      position: relative;
      top: 0;
      margin-right: 10px;
    }
  }
}
