// base elements
.tabs {

  a {
    background-color: $yellow;
    color: $black;
    font-size: $normal;

    &.active,
    &:hover,
    &:focus {
      background-color: $yellow;
      color: $black;
    }
  }
}

p {
  margin-bottom: 2em;

  &:last-child {
    margin-bottom: 0;
  }
}

textarea,
input,
button,
a {
  outline: none;
}

.l-content {
  .node,
  .block {

    ul {
      list-style: disc;
      padding-left: 1em;
      margin-left: 0;
      margin-bottom: 2em;
      //overflow: hidden;

      li {
        margin-bottom: 0.1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    ol {
      list-style: decimal;
      padding-left: 1em;
      margin-left: 0;
      margin-bottom: 2em;

      li {
        margin-bottom: 0.1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.menu {
  list-style: none;
  list-style-image: none;

  .leaf {
    list-style: none;
    list-style-image: none;
  }
}

strong {
  font-weight: 700;
}

u {
  text-decoration: line-through;
}

em {
  font-style: italic;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

sup {
  vertical-align: super;
  font-size: smaller;
}