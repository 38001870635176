@import "susy";
@import 'plugins/svg-grid';
@import "breakpoint";

@import "variables/_sprites.scss";
@import "variables/_grid.scss";
@import "variables/_typography.scss";
@import "variables/_colors.scss";
@import "abstractions/_mixins.scss";
@import "base/_essentials.scss";
@import "components/_slick.scss";
@import "components/_slicknav.scss";
@import "components/_selectboxit.scss";
@import "components/_base_components.scss";

img {
  height: auto;
  max-width: 100%;
}

html {
  font-size: 22px;
  line-height: 1.4;
  font-family: $mada;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.slicknav_menu {
  display: none;
}

.logged-in {
  #block-views-footer-region-switcher-block {
    position: static;
  }
}

body {
  // line height inherit to move to html tag, because we have a reset rule to make it 1 in body
  line-height: inherit;
  color: $black;

  // fixed header fix
  padding-top: 70px;
}

// Frontpage theming

// frontpage group-meta transition

.below-sect-wrapper {
 // animation: frontblocks 1s ease-out 1.3s forwards;
  transition: all 1s ease-out 0.8s;
  opacity: 0;
  top: 0;

  .below-banner-title {
    position: relative;
    padding-bottom: 8px;
    display: inline-block;

    &:after {
      content: "";
      transition: transform 1s ease-out 0.8s;
      transform-origin: 0 0;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $yellow;
      transform: scaleX(0);
      width: 100%;
      height: 5px;
    }
  }

  &.anim-loaded {
    top: -80px;
  //  transform: translateY(-80px);
    opacity: 1;

    .below-banner-title {
      &:after {
        transform: scaleX(1);
      }
    }
  }
}

.front {
  .l-header-wrapper {
  //  animation: frontmenu 1s ease-out 1.3s forwards;
    transition: all 1s ease-out 0.8s;
    transform: translateY(-100%);
    opacity: 0;

    &.anim-loaded {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.messages {
  margin-bottom: 20px;
  max-width: $container;
  margin: 0 auto;
}

/*body {
  &.front {
    padding-top: 0;
    transition: padding 0.5s ease-out 2.3s;

    &.anim-loaded {
      padding-top: 70px;
    }
  }
}*/

.vcurtain {
  position: absolute;
  height: 100%;
  width: 25%;
  background-color: $white;
  top: 0;
  z-index: 100;
  transition: all 0.8s ease-out;
 // animation: reveal 1s ease-out 0.3s forwards;

  &-1 {
    left: 0;
  }

  &-2 {
    left: 25%;
  }

  &-3 {
    left: 50%;
  }

  &-4 {
    left: 75%;
  }

  &.anim-loaded {
    transform: scaleX(0);
  }
}

.top-sect {
  margin-bottom: 0;
}

#how-we-do-it {
 &:before {
   content: "";
   display: block;
   height: 90px;
   margin-top: -90px;
   visibility: hidden;
 }
}

.view-offices {
  .inner-row {
    &:before {
      content: "";
      visibility: hidden;
      margin-top: -90px;
      height: 90px;
      display: block;
    }
  }

  .views-row {
    margin-bottom: $page-spacing;
    @include clearfix;

    // effect slidein
    &-odd {
      .group-office-image {
        opacity: 0;
        transform: translateX(-5%);
      }
    }

    &-even {
      .group-office-image {
        opacity: 0;
        transform: translateX(5%);
      }
    }
    transition: all 0.6s ease-out;


    .group-office-image {
      transition: all 0.6s ease-out;

      &.anim-loaded {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .group-office-info {
      transition: all 0.6s ease-out;
      opacity: 0;
      transform: translateY(10%);

      &.anim-loaded {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &:last-child {
      .inner-row {
        margin-bottom: 0;
      }
    }

    .group-office-image {
      width: span(8);
    }

    group-office-info {
      width: span(3);
    }

    &-odd {
      .group-office-image {
        float: left;
        margin-right: gutter();
      }

      .group-office-info {
        width: span(3);
        float: left;
      }
    }

    &-even {
      .group-office-image {
        float: right;
      }

      .group-office-info {
        width: span(3);
        float: left;
        margin-left: span(1 wide);
      }
    }
  }
}

.last-sect {
  @include container($container);

  .read-more {
    margin-top: 40px;
  }

  &-title {
    font-size: $huge;
    font-weight: 700;
    line-height: 1.1;

    // layout
    max-width: statcol(7);
  }

  margin-bottom: $sect-marg;
}

.below-sect-wrapper {
  max-width: $container + (2 * gutter($susy-static));
  margin: 0 auto;
  @include clearfix;
  padding: gutter($susy-static) gutter($susy-static) 0 gutter($susy-static);
  background-color: $white;
  position: relative;
  z-index: 100;
}

.let-us-know-wrapper {
  margin-top: $reg-marg;

  .read-more-wrapper {
    margin-top: $sect-marg - 8px;
  }
}

.below-sect {
  @include clearfix;
  text-align: center;
//  background: susy-svg-grid() no-repeat scroll;

  .below-banner-block {
    vertical-align: top;
    text-align: left;
  //  width: span(2 wide);
    max-width: span(2 wide);
    margin-right: span(1 wider);
    // margin-right: span(1);
 //   float: left;
    display: inline-block;

    &:hover {
      .read-more {
        transform: translateX(0);
        opacity: 1;
      }
    }

    .read-more {
      color: $black;
      opacity: 0;
      margin-top: 40px;
      background: transparent;
      transform: translateX(-20%);
      transition: all 0.3s ease-out;

      &:after {
        @include sprite($sprite-readmore-arrow-black);
      }

      &:hover,
      &:focus {
        background: transparent;
      }

      a {
        color: $black;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .below-banner-title {
      font-size: $bigger;
      font-weight: 700;
   //   margin-bottom: 20px;
      margin-bottom: 12px;
    }

    .below-banner-content {
      &.regions-list {
        @include links($black, $yellow, none, 400);

        a {
          display: block;
        }
      }
    }
  }
}

.main-videos-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: calc(93.2vh - 80px);
  min-height: 480px;
  z-index: -1;

  iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
   // min-height: 480px;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .titled-mega {
    position: absolute;
    z-index: 20;
    left: 50%;
    top: 50%;
    margin-top: -40px;
    transform: translate(-50%, -50%);
    color: $white;
    width: 100%;
    max-width: $container;

    .titled-title {
      font-size: $giga;
      font-weight: 700;
      line-height: 0.9;
      margin-bottom: 30px;
      max-width: statcol(7);
    }

    .titled-text {
      max-width: statcol(7);
      ul {
        li {
          font-size: $lar;
          display: inline-block;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.6);
  }
}

.logos-fsec {
  padding-bottom: $reg-marg;

  .logos-title {
    font-size: $bigger;
    font-weight: 700;
    margin-bottom: $sect-marg;
  }

  .views-field-field-images {
    float: left;
    margin-right: gutter(of 5);
    width: span(1 of 5);

    &:nth-child(5n) {
      margin-right: 0;
      margin-bottom: gutter(of 5);
    }

    &:nth-child(5n+1) {
      clear: both;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .fsec-clients {
    width: span(5);
    margin-right: span(1 wider);
    float: left;
  }

  .fsec-collabs {
      width: span(5);
      float: left;
  }
}

//

.l-header,
.l-content,
.l-footer,
.l-below-content,
.l-subfooter,
.front-sect {
  @include container($container);
  @include clearfix;
  // background: susy-svg-grid() no-repeat scroll;
}

.l-header {
  @include clearfix;
  height: 70px;
}

.front {
  .l-content {
    padding-top: 0;
    max-width: none;
  }
}

.l-subfooter {
  padding: 30px 0;
}

.l-branding {
  max-width: statcol(2);
  float: left;
}

.l-region {
  &--header {
    float: right;
    margin-left: span(2 wide, $susy-static);
    position: absolute;
    top: 0;
    right: 0;
  }
}

.clientside-error {
  li {
    color: $error;

    label.error {
      color: $error;
    }
  }
}

.messages--error,
.error {
  color: $black;
}

.block {
  &--system-main-menu {
    float: right;

    li {
      float: left;
    }

    a {
      display: block;
      padding: 24px 30px;
      font-weight: 400;
      font-size: $normal;
      text-decoration: none;
      position: relative;
      color: $black;

      @include lineover($yellow);

      &.active {
        &:after {
          transform: scaleX(1);
        }
      }
    }

    .gtn-button {
      a {
        color: $white;
        background-color: $black;
        padding: 25px 51px;

        .overlay-title {
          position: relative;
          z-index: 50;
          color: $white;
        }

        &:hover,
        &:focus,
        &.active {
          &:after {
            background-color: $yellow;
            transform: scaleX(1);
          }
        }
      }
    }
  }
}

.l-header-wrapper {
  background: $white;
  position: relative;
}

.block--gtweaks-copyright {
  font-size: $slight;
}

.l-footer-wrapper {
  background-image: linear-gradient(to bottom, $white 0, $white 90px, $light_gray 90px, $light_gray 100%);
  position: relative;
  padding-top: 90px;
  margin-top: $reg-marg;
}

.l-subfooter {

  .cp-left {
    float: left;
  }

  .cp-right {
    float: right;

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .fl-nv {
    margin-right: 50px;
    @include sprite($sprite-neverending-logo);
  }

  .fl-es {

    .fl-tx {
      float: left;
      margin-right: 20px;
    }

    .fl-ic {
      position: relative;
      top: -3px;
      @include sprite($sprite-esepia-logo);
    }
  }

  .cp-text {
    float: left;
    display: block;
  }

  .social-media-icons {
    float: left;
    margin-left: 20px;

    &__item {
      float: left;
      margin-right: 12px;

      &--youtube {
        @include sprite($sprite-youtube-footer);
        position: relative;
        top: 2px;
      }

      &--linkedin {
        @include sprite($sprite-linkedin-footer);
      }

      &--vimeo {
        @include sprite($sprite-vimeo);
        position: relative;
        top: 2px;
      }

      &--facebook {
        @include sprite($sprite-facebook);
        position: relative;
        top: 2px;
      }
    }
  }
}

.file {
  @include links($black, $black, none, 400);
}

.site-logo {
  display: block;
  margin: 10px 0;
}

/* intro spacing
* Also for node type Page don't do l-main container because we have fullscreen images
*/


.node-type {
  &-page {

    .l-footer-wrapper {
      margin-top: 0;
    }

    .group-text-section {
      @include container($container);
    }

      .group-image-section {
        position: relative;
      }

    .l-content {
    //  padding-top: $page-spacing;
      max-width: none;
    }
  }

/*  &-artist {
    .l-content {
  //    padding-top: $page-spacing;
    }
  }*/
}

.l-content {
  padding-top: $page-spacing;
}

.page-node-1 {
  .field--name-field-page-section .field__items .field__item:first-child {
    .field--name-field-page-section-title {
      margin-bottom: $reg-marg;
    }
  }
}

.ajax-progress-throbber {
  display: none;
}

.l-below-content {
  overflow: hidden;

  .block {
    margin-bottom: $reg-marg;

/*    &:last-child {
      margin-bottom: 0;
    }*/
  }
}

#block-views-artists-projects-block {
  margin-bottom: 0;
}

.view-projects {
  &.view-display-id-page {

    .views-exposed-widgets {
      @include clearfix;
      position: relative;
      margin-bottom: $reg-marg;

      #edit-field-project-category-tid {
        display: none;
      }

      .views-widget-filter-field_project_category_tid {

        .selected-mopt {
          letter-spacing: 0.18em;
        }

        .mopt {
          user-select: none;
          font-size: $huge;
          width: statcol(3);
          font-weight: 400;
          float: left;
          line-height: 1.1;
          position: relative;
          display: block;
          padding-right: 25px;

          &:hover,
          &:focus {
            cursor: pointer;
          }

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            border-top: 8px solid $black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
          }

          &.mop-selected {
            &:after {
              border-bottom: 8px solid $black;
              border-top: none;
              border-right: 8px solid transparent;
              border-left: 8px solid transparent;
            }
          }
        }

        .list-optw {
          clear: both;
          position: relative;
        }

        .list-menu-opt-wrapper {
          position: absolute;
          display: none;
          top: 0;
          left: 0;
          z-index: 100;
          font-size: $huge;
          font-weight: 400;
          background-color: $white;
          padding-left: span(4 wide, $susy-static);
          width: 100%;
          padding-bottom: 120px;
          padding-top: 12px;

          li {
            padding-bottom: 4px;

            &:hover,
            &:focus {
              cursor: pointer;
            }

            &:last-child {
              padding-bottom: 0;
            }
          }
        }

        label {
          display: block;

          h1 {
            letter-spacing: -0.01em;
            float: left;
            margin-bottom: 0;
            max-width: statcol(4);
            width: 100%;
            margin-right: gutter($susy-static);
          }
        }
      }
    }

    .views-exposed-widget {
      float: none;
      padding: 0;
    }

    .project-single-line {
      .node {
        width: span(8);
        margin: 0 auto;
      }

      margin-bottom: $reg-marg;
    }

    .project-duo-line {
      @include clearfix;
      margin-bottom: $reg-marg;

      .node {
        width: span(6);
        margin-right: gutter();
        float: left;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .views-row:last-child {
      .project-single-line,
      .project-duo-line {
        margin-bottom: 0;
      }
    }
  }
}

// View Styling

.view-team-lineup {
  @include clearfix;
  max-width: statcol(10);
  margin: 0 auto;

  .views-row {
    width: span(5 of 10);
    margin-right: gutter(of 10);
    float: left;

    &:nth-child(2n) {
      margin-right: 0;
      margin-bottom: 100px;
    }

    &:nth-child(2n+1) {
      clear: both;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.artist-lineup-block {
  @include clearfix;

  .views-row {
    width: span(10);
    margin-bottom: $reg-marg;
    transition: all 0.6s ease-out;

    &-odd {
      float: left;
      margin-right: span(2 wide);
      opacity: 0;
      transform: translateX(5%);
    }

    &-even {
      float: right;
      margin-left: span(2 wide);
      opacity: 0;
      transform: translateX(-5%);
    }

    &.anim-loaded {
      opacity: 1;
      transform: translateX(0);
    }

    &-last {
      margin-bottom: 0;
    }
  }
}

.group-office-center {
  line-height: 1.4;

  .custom-title {
    margin-bottom: 40px;
    font-weight: 700;
  }

  .group-office-contact {
    margin-top: 40px;
  }
}

.switcher-main {
  @include clearfix;

  .field--name-field-office-footer-image {
    position: relative;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.7);
        left: 0;
        top: 0;
      }
  }

 .attachment-before {
   min-width: statcol(3);
   float: left;
   height: calc(731px - 90px);
   position: relative;

   .view-footer-region-switcher {
     position: absolute;
     top: 50%;
     left: 0;
     transform: translateY(-50%);
   }

   @include links($gray, $black, none, 700);

   .active-region {
     a {
       color: $black;
       font-weight: 700;
     }
   }

   .block__title {
     margin-bottom: 30px;
     font-size: $normal;
     font-weight: 400;
   }

   .views-row {
     margin-bottom: 30px;
     font-size: $bigger;
   }
  }

 > .view-content {
    position: absolute;
    right: 0;
    bottom: 0;
   // margin-left: statcol(3);
   margin-left: span(3 wide, $susy-static);

    .views-row {
      display: none;

      &.animate-show {
        display: block;
       // animation: slide-down 0.3s linear;
      }
    }

   .views-row-first {
     display: block;
   }

   img {
     display: block;
   }
  }
}

.l-header-wrapper {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 200;
}

/*@keyframes slide-down {
  0% { transform: scaleY(0);}
  100% { transform: scaleY(1);}
}*/

// Node View Mode Styling

.page-node-41 {
  .field--name-field-page-section-body {
    .read-more-wrapper {
      margin-top: -0.6em;
    }
  }
}

.cat-wrap {
  @include clearfix;

  .select-cat-menu,
  .read-more {
    float: left;
    margin-right: gutter($susy-static);
  }

  .read-more {
    background-color: $inactive;
  }

  .active-catab {
    background-color: $yellow;
  }

  .cat-name {
    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &:focus {
      cursor: pointer;
      background-color: $black;
      color: $white;
    }
  }

  .select-cat-menu {
    margin-top: 10px;
  }
}

.cat-content {
  margin-top: 120px;

    .cat-item {
      display: none;

      &:first-child {
        display: block;
      }
    }

  .field--name-field-faq {
    .field-collection-view {
      margin-bottom: 80px;
    }

    .field-collection-view-final {
      margin-bottom: 0;
    }
  }

  .field--name-field-faq-question {
    font-weight: 700;
    font-size: $bigger;
    margin-bottom: 20px;
  }

  .field--name-field-faq-answer {
    font-size: $larger;
    line-height: 1.44;
  }
}

// disable body scrolling when modal is open

body {
  &.disable-scrolling,
  &.overlay-menu-open {
    overflow: hidden;
  }
}

.special-list {

  .page-section-title {
    margin-bottom: 0;
  }

  .group-text-section {
    @include clearfix;
  }

  .field--name-field-page-section {

   &-title {
     float: left;
     width: span(5);
     margin-right: gutter();
     margin-bottom: 0;

     .field__items {
       max-width: span(3 of 5, $susy-static);
     }
   }
    &-body {
      float: left;
      width: span(7);

      ul {
        margin-left: 0;
        padding-left: 0;
        margin-bottom: 0;

        li {
          position: relative;
          transition: all 0.3s ease-in-out;

          &:before {
            content: "";
            position: absolute;
            width: 5px;
            margin: 50px 0;
            top: 0;
            left: 0;
            bottom: 0;
            transform: scaleY(0);
            background-color: $yellow;
            transition: all 0.3s ease-in-out;
          }

          &.highlighted-slist {
            padding-left: 30px;

            &:before {
              transform: scaleY(1);
            }
          }

          list-style: none;
          border-bottom: 1px solid $list_border;
          padding: 50px 0;

          &:first-child {
            padding-top: 0;

            &:before {
              margin-top: 0;
            }
          }

          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;

            &:before {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

h1,
.block__title {
  font-weight: 700;
  font-size: $huge;
  margin-bottom: $sect-marg;
  line-height: 1.1;
}

.field--name-field-image,
.field--name-field-video {
  img {
    display: block;
  }
}

.field--name-field-video {
  position: relative;
}

.block--views-artists-projects-block {
  max-width: span(10);
  margin: 0 auto;
  @include clearfix;
  margin-top: $mid-marg;

  .custom-title {
    font-size: $bigger;
    font-weight: 700;
    line-height: 1.4;
  }

  .group-meta {
    margin-top: 20px;
  }

  .node__content {
    text-transform: uppercase;
  }

  .views-row {
    width: span(5 of 10);
    margin-right: gutter(of 10);
    float: left;

    &:nth-child(2n) {
      margin-right: 0;
      float: right;
      margin-bottom: $mid-marg;
    }

    &:nth-child(2n+1) {
      clear: both;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

#block-gtweaks-project-previous-next {
  margin-bottom: 0;
}

.field-artist-quote {
  max-width: span(10);
  margin: $mid-marg auto 0 auto;
  padding: $mid-marg 0;
  border-top: 1px solid $list_border;
  border-bottom: 1px solid $list_border;
  position: relative;

  &.artist-quote-empty {
    padding: 0;
    border-top: none;
    border-bottom: none;
    margin-top: 0;
  }

  &.artist-image-empty {
    .field--name-field-quote-text {
      width: 100%;
      float: none;

      .field__items {
        position: static;
        top: 50%;
        left: auto;
        transform: none;
      }
    }
  }

  .entity-field-collection-item {
    @include clearfix;
  }

  .field--name-field-image {
    width: span(5 of 10);
    float: left;
    margin-right: span(1 wider of 10);
  }

  .artist-name-quote {
    position: relative;
    margin-top: 30px;
    font-size: $slight;

    @include middle-line(60px);
  }

  .field--name-field-quote-text {
    width: span(4 of 10);
    float: left;
    font-size: $larger;

    .field__items {
      position: absolute;
      top: 50%;
      left: auto;
      transform: translateY(-50%);
    }
  }
}

.featured-project-sp {

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
  }
}

/*html{
  overflow: hidden;
  height: 100%;
}
body{
  overflow: auto;
  height: 100%;
}*/

.featured-sect {
  margin-bottom: $reg-marg;

  .fsec-title {
    @include container($container);
    font-size: $huge;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: $sect-marg;
  }
}

.node {
  &--project {

    &--teaser {

      .project-duo-line {
        .field--name-field-sound {
          max-height: 450px;
        }
      }

      .group-meta {
        margin: 30px 0;
        @include clearfix;
        min-height: 92px; // todo

        .field {
          font-size: $bigger;
          width: span(3 of 6);
          margin-right: gutter(of 6);
          float: left;

          &:last-child {
            margin-right: 0;
          }
        }

        .custom-title {
          color: $black;
          font-weight: 700;
          line-height: 1.1;
          margin-top: 4px;
          @include links($black, $black, none, 700);
        }
      }
    }

    &--featured {
      position: relative;
      overflow: hidden;

      .group-meta {
        transition: all 1s ease-out;
        margin-bottom: -200px;
        opacity: 0;

        &.slide-meta {
          margin-bottom: 0;
          opacity: 1;
        }
      }

      .field--name-field-video {
        position: static;
      }

      .field--name-field-project-brand {
        line-height: 1.2;
      }

      .custom-title {
        font-size: $huge;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 30px;
      }

      .read-more {
        margin-top: 70px;
      }

      .alt-read {
        .read-more {
          margin-top: 20px;
          background-color: $white;

          &:hover,
          &:focus {
            background-color: $black;
            color: $white;
          }
        }
      }

      .vidplay {
        z-index: 20;

        &:before,
        &:after {
          z-index: 20;
        }
      }

      .featured-project-sp {
        position: relative;
        display: block;
        height: 100vh;
        max-width: 100%;
        background-size: cover;
        background-attachment: fixed;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        z-index: 1;
        min-height: 600px
      }

      .group-meta-wrapper {
        @include container($container);
        z-index: 10;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:hover,
        &:focus {
          cursor: pointer;
        }
      }

      .vidplay {
        &:hover,
        &:focus {
          cursor: pointer;
        }
      }

      .group-meta {
        color: $white;
        max-width: statcol(4);

        &:hover,
        &:focus {
          cursor: pointer;
        }
      }
    }

    &--full {
      h1 {
        margin-bottom: 0;
      }

      .group-left-right-wrapper {
        @include clearfix;
       // margin-bottom: $reg-marg;
        margin-bottom: $sect-marg;
        font-size: $larger;
      }

      .field--name-field-project-release-date {
        font-size: $huge;
        font-weight: 700;
        line-height: 1.1;
      }

      .field {
        margin-bottom: $sect-marg;

        &.field--name-field-video {
          margin-bottom: $mid-marg;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .custom-title {
        line-height: 1.1;
      }

      .group-left-side {
        float: left;
        width: span(5);
        margin-right: span(2 wider);
      }

      .group-optional-right-side {
        float: left;
        width: span(5);
      }
    }

    .field .field__label {
      font-weight: 400;
      font-size: $base;
      line-height: 1.2;
      padding-bottom: 4px;
    }
  }

  &--artist {

    .read-more-arrow {
      height: 50px;
      width: 180px;
      position: relative;
      left: -29px;
      background-color: transparent;
      background-image: linear-gradient(to right, $black, $black 50%, transparent 50%, transparent);
      background-size: 202% 100%;
      background-repeat: no-repeat;
      background-position: 100% 0;
      transition: background-position 0.25s ease-in-out;

      &:hover,
      &:active {
        background-color: transparent;
        background-position: 0 0;
      }
    }

    &--full {
      .artist-text-wrapper {
        .field--name-field-image {
          margin: $mid-marg 0;
        }
      }

      .artist-text-reg {
        max-width: statcol(7);
        font-size: $larger;
      }
    }

    &--teaser {
      position: relative;

      .field--name-field-image {
        a {
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.3);
          }
        }
      }

      .group-artist-teaser-meta-wrapper {
        color: $white;

        .field--name-field-artist-job-description {
          margin-top: 3px;
        }

        .group-artist-teaser-meta {
          @include clearfix;
          color: $white;
          text-decoration: none;
          display: block;
          position: absolute;
          top: 50%;
          transform: translate(-50%,-50%);
          left: 50%;
          width: span(6 of 10);
          z-index: 10;
        }

        .custom-title {
          @include links($white, $white, none, 700);
          color: $white;
          font-weight: 700;
          font-size: $huge;
          line-height: 1;
        }
      }
    }
  }

  &--office {
    &--teaser {
      @include links($white,$white, none, 400);
      position: relative;
      color: $white;

      .group-office-center {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--full {
      @include links($black,$black, none, 400);

      .read-more-wrapper .read-more {
        color: $black;
        background-color: $white;
        margin-top: $sect-marg;

        &:hover,
        &:focus {
          background-color: $black;
          color: $white;
        }
      }

      .group-office-contact-full {
        margin-top: $sect-marg;
      }

      .custom-title,
      .group-office-contact-full > div > span {
        font-weight: 700;
        font-size: $bigger;
        line-height: 1.4;
      }
    }
  }

  &--team {
    &--teaser {
      .custom-title {
        text-transform: uppercase;
      }

      .field--name-field-team-job-description {
        font-size: $slight;
      }

      .group-team-desc {
        margin-top: 20px;
      }

      .custom-title {
        margin-bottom: 4px;
      }

      .field--name-field-team-job-description {
        letter-spacing: 0.06em;
      }

      .field--name-field-region {
        .field__item {
          @include middle-line(60px);
        }
      }

      .group-team-social {
        margin-top: 40px;

        @include links($black, $black, none, 400);

        .social-wrap {
          a {
            display: inline-block;

            &:hover {
              .text {
                opacity: 1;
                transform: translateX(0);
              }
            }
          }
        }

        .linkedin-c {
          height: $sprite-linkedin-c-height;
          line-height: $sprite-linkedin-c-height;
          @include clearfix;

          .icon {
            @include sprite($sprite-linkedin-c);
            display: block;
            margin-right: 15px;
            float: left;
          }

          .text {
            display: inline-block;
            transition: all 0.3s ease-out;
            opacity: 0;
            transform: translateX(-8px);
            vertical-align: middle;
          }
        }
      }

      .field--name-field-region {
        margin-bottom: 7px;
      }
    }
  }
}

.project-single-line {
  .node--project {
    .group-meta {
      .field {
        font-size: $bigger;
        width: span(3 of 8);
        margin-right: gutter(of 8);
        float: left;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.arrow-wrapper {
  &--left {
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 20;

    &:hover,
    &:focus {
      cursor: url('../images/slick-arrow-left.png'), auto;
    }
  }

  &--right {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 20;

    &:hover,
    &:focus {
      cursor: url('../images/slick-arrow-right.png'), auto;
    }
  }
}