@keyframes pulse {
  to {
    transform: scale(1.1);
  }
}

@keyframes shakeprev {
  50% {
    left: 12px;
  }

  100% {
    left: 0;
  }
}

@keyframes shakenext {
  50% {
    left: -12px;
  }

  100% {
    left: 0;
  }
}

/*
@keyframes draw {
  to {stroke-dashoffset: 0;}
}

@keyframes fillin {
  to {fill-opacity: 1; stroke-opacity: 0}
}*/

// Main titles

.field--name-field-page-main-section-title,
.field--name-field-page-section-title,
.project-boxes__title {
  font-size: $huge;
  font-weight: 700;
  line-height: 1.1;

  // layout
  max-width: statcol(7);
  margin-bottom: $sect-marg;
}

.field--name-field-faq {
  max-width: statcol(7);
}

.field--name-field-page-section-body,
.group-below-optionals {
  font-size: $larger;
  font-weight: 400;
  line-height: 1.44;

  // layout
  max-width: statcol(7);
//  margin-bottom: $sect-marg;
}

.field--name-field-project-credits {
  font-size: $bigger;
  font-weight: 400;
  line-height: 1.44;
}

.block--gtweaks-artists-join-btn {
  &.block.block--gtweaks {
    margin-bottom: 0;
  }
}

ul.pager {
 // margin: $mid-marg 0;
  margin: $mid-marg 0 0 0;

  li {
    @include links($black, $black, none, 400);

    &.pager__item--current {
      font-weight: 400;
      padding: 0;
      display: inline-block;
      width: 38px;
      height: 38px;
      line-height: 38px;
      border-radius: 50%;
      text-align:  center;
      vertical-align: middle;
      background-color: $yellow;
    }

    a {
      display: inline-block;
      width: 38px;
      height: 38px;
      line-height: 38px;
      border-radius: 50%;
      text-align:  center;
      vertical-align: middle;

      &:hover,
      &:focus {
        background-color: $yellow;
      }
    }
  }
}

.read-more {
  display: block;
  padding: 10px;
  text-align: center;
  width: 180px;
  height: 50px;
  background-color: $yellow;
  font-size: $slight;
  text-decoration: none;
  color: $black;
  font-weight: 400;
  line-height: 30px;
  vertical-align: middle;
  box-shadow: 4px 4px 14px rgba(0,0,0, 0.18);


  &-arrow {
      box-shadow: none;
      text-decoration: none;
      color: $white;
      font-weight: 400;
      padding: 0;
      margin-top: $sect-marg;
      background-color: transparent;
      vertical-align: middle;
      line-height: 50px;
      width: auto;
      float: left;

      &:after {
        margin-left: 18px;
        content: "";
        line-height: 50px;
        vertical-align: middle;
        display: inline-block;
        @include sprite($sprite-readmore-arrow);
      }
  }

  &:hover,
  &:focus {
    background-color: $black;
    color: $white;
  }
}

.parallax-full {
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  min-height: 960px;
  position: relative;
  background-repeat: no-repeat;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0,0,0,0.6);
  }

  &__title {
/*    top: 50%;
    right: 50%;
    transform: translate(-50%,-50%);*/
    transform: translate(50%,50%);
    bottom: 50%;
    right: 50%;
    position: absolute;
    font-weight: 700;
    line-height: 1.18;
    color: $white;
    font-size: $huge;
    text-align: center;
    width: 100%; // force content to take max-width space
    max-width: statcol(8);
    margin: 0 auto;
    padding: 80px 0;
    z-index: 10;

    &:before {
      content: "";
      background-color: $white;
      height: 1px;
      width: span(6 of 8);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &:after {
      content: "";
      background-color: $white;
      height: 1px;
      position: absolute;
      width: span(6 of 8);
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.field--name-field-page-section {

  .field-group-html-element {
    &.group-text-section {
      margin-bottom: $reg-marg;
    }

    &.group-image-section {
      margin-bottom: $reg-marg;
    }
  }

  .field__items .field__item:last-child {
    .field-collection-view {
      margin-bottom: 0;
    }
  }
}

.vidplay {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 242px;
  height: 242px;

  img {
    width: auto;
    height: 40px;
    left: 50%;
    top: 50%;
    margin-top: -20px;
    margin-left: -13px;
    position: absolute;
  }

  &--teaser,
  &--full,
  &--featured {
    &:before {
      content: "";
      display: block;
      width: 136px;
      height: 136px;
      border-radius: 50%;
      background-color: $white;
      // opacity: 0.14
      opacity: 0.16;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }

    &:after {
      content: "";
      display: block;
      width: 242px;
      position: absolute;
      height: 242px;
      border-radius: 50%;
      background-color: $white;
      // opacity: 0.07;
      opacity: 0.09;
      left: 0;
      top: 0;
      animation: pulse 2s ease-in-out infinite alternate;
    }
  }

  &--full {

  }
}

.trigger-modalvid,
.trigger-replacevid {
  &:hover,
  &:focus {
    cursor: pointer;
  }
}

// general video styles
// resp video, sound

.embedded-video .player,
.node-teaser .field--type-soundcloud .field__item,
.node-nextprev .field--type-soundcloud .field__item {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

.project-duo-line .node-teaser .field--type-soundcloud .field__item,
.node-nextprev .field--type-soundcloud .field__item {
  padding-bottom: 57.45%;
}

.project-duo-line .node-teaser .field--type-soundcloud .field__item,
.node-nextprev .field--type-soundcloud .field__item {
  iframe {
    max-height: 450px;
  }
}

.embedded-video .player,
.node-teaser .field--type-soundcloud .field__item,
.node-nextprev .field--type-soundcloud .field__item {
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

#vidmodal {

   .cs-modal-header {
     max-width: 58vw;
     text-align: right;
     margin: 0 auto;
     margin-bottom: 30px;
     line-height: 0;
   }

   .close-vidmodal {

     &-btn {
       position: relative;
       right: -80px;
       display: inline-block;
       width: 52px;
       height: 52px;
       overflow: hidden;
       border-radius: 50%;
       border: 2px solid $black;

       &:hover {
         &:before {
         }

         &:after {
         }
       }

       &:before,
       &:after {
         content: '';
         transition: transform 0.3s ease-out;
         position: absolute;
         height: 3px;
         width: 22px;
         margin-left: -11px;
         left: 50%;
         top: 50%;
         margin-top: -1px;
         background: $black;
       }

       &:before {
         content: "";
         transform: rotate(45deg);
       }

       &:after {
         content: "";
         transform: rotate(-45deg);
       }
     }
   }

   text-align: center;
  // background: rgba(255, 255, 255, 0.8);
   background: transparent;
   box-shadow: none;

   .embedded-video {
     display: inline-block;
     width: 100%;
     height: 100%;
     max-width: 58vw;
   }

   .player {
     position: relative;
     padding-bottom: 56.25%; /* 16:9 */
     // padding-top: 25px;
     overflow: hidden;
     height: 0;

     iframe {
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       height: 100%;
     }
   }
 }

.project-boxes {
  max-width: statcol(10);
  @include clearfix;
}

.project-box {
  text-align: center;
  float: left;
  margin-right: gutter(of 10);
  width: span(2 of 10);

  &:nth-child(5n) {
    margin-right: 0;
    margin-bottom: $mid-marg;
  }

  &:nth-child(5n+1) {
    clear: both;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__icon {
    margin-bottom: 10px;

    img {
      height: 60px;
      width: auto;
    }

    /*
    svg {
      height: 60px;
      width: auto;

      path {
        fill-opacity: 0;
        stroke-width: 0.2;
        stroke: $black;
        stroke-miterlimit: 10;
      }
    }

    &.animatesvg svg path {
      animation: draw 2s 1s linear forwards, fillin 2s 3s linear forwards;
    }*/
  }

  &__text {
  }
}

.back-link-wrap {
  @include links($black, $black, none, 400);
  margin-top: -$page-spacing + 40px;
  margin-bottom: $page-spacing - 40px;

  .back-link {
    font-size: $slight;

    &:before {
      margin-right: 18px;
      content: "";
      vertical-align: middle;
      transform: rotate(-180deg);
      display: inline-block;
      @include sprite($sprite-readmore-arrow-black);
    }
  }
}

.iziModal.hasShadow {
  &:after {
    opacity: 0;
  }
}

.marker,
.form-required {
  color: $error;
}

#formmodal {

  /* Overrides /modules/system/system.base.css with a custom throbber. */

  /* these apply to all ajax progresses */
  .ajax-progress.custom-prog {
    display: block;
    *zoom: 1;
  }

  .ajax-progress.custom-prog .throbber {
    background: transparent url('../images/ajax-throbber.gif') no-repeat 0px 0px;
    float: none;
    height: 26px; /* tweak this according to your gif */
    width: 26px; /* tweak this according to your gif */
    margin: 0;
  }

  .remove-button-wrapper {
    .ajax-progress {
      display: none;
    }
  }

  .webform-component--upload_your_files {
    margin-top: 20px;
  }

  ul.file-widget {

    li {
      @include clearfix;
      font-size: $slight;
      margin-bottom: 18px;
      padding-bottom: 18px;
      border-bottom: 1px solid $border;

      .file {
        img {
          display: block;
          margin-right: 8px;
          float: left;
        }

        a {
          display: block;
          overflow: hidden;
          text-align: left;
          word-break: break-all;
        }
      }
    }

    .form-managed-file {
      float: left;
      width: 75%;
      margin-right: 7%;
    }
  }

  .file-uploaded-browser-wrapper {
    display: block;

     div {
      display: block;
    }

    @include clearfix;
    font-size: $slight;
    margin-bottom: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid $border;

    .file {
      display: block;
      float: left;
      width: 75%;
      margin-right: 7%;
      text-align: left;

      img {
        display: block;
        margin-right: 8px;
        float: left;
      }

      a {
        display: block;
        overflow: hidden;
        text-align: left;
        word-break: break-all;
      }
    }
  }

  .remove-button-wrapper {
    float: right;
    width: 18%;
    text-align: right;

    input[type="submit"] {
      font-size: $small;
      display: block;
      margin-top: 0px;
      padding: 4px 32px 4px 0;
      background: url('../images/delete-file.png') right center no-repeat;
      text-align: center;
      height: auto;
      min-width: 0;
      background-color: transparent;
      text-decoration: none;
      line-height: 1.4;
      vertical-align: baseline;
    }
  }

  .file-original-browse-wrapper-inner {
    overflow: hidden;
    display: block;
  }

  .file-original-browse-wrapper {
    float: left;
    width: 55%;
    margin-right: 8%;
    font-size: $slight;

    &:before {
      content: "";
      @include sprite($sprite-upload-file);
      display: block;
      float: left;
      margin-right: 10px;
      margin-top: 18px;
    }
  }

  .file-custom-browse-wrapper {
    float: right;
    width: 37%;
    font-size: $slight;
    display: block;
    position: relative;

    .throbber {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }

  .file-custom-browse {
    border: 1px solid $black;
    padding: 8px 14px;
    width: 100%;
    text-align: center;
    display: block;

    &:hover,
    &:focus {
      background-color: $black;
      color: $white;
      cursor: pointer;
    }
  }

  .form-wrapper {
    .fieldset-legend {
      font-size: $slight;
    }
  }

  .form-item {
    position: relative;

    &.webform-component-file,
    &.webform-component-multiple-file {
      @include clearfix;
      float: none;
      margin-bottom: 50px;
      width: 100%;
      margin-right: 0;

      div.description {
        font-size: $small;
      }
    }
  }

  // first child visible on load, the rest need jquery
  .webform-component-fieldset {
    display: none;
    min-height: 360px;

    &:first-child {
      display: block;
    }
  }

  .messages {
    font-size: $slight;
    padding: 6px;
    margin: 0 0 20px 0;
  }

  .modal-form-content {
    max-width: 780px;
    margin: 0 auto;
  }

  .node--webform {
    max-width: 580px;
    @include clearfix;
  }

  .form-item {
    float: left;
    margin-bottom: 20px;
    width: 48%;
    margin-right: 4%;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(2n+1) {
      clear: both;
    }

    &.webform-component-file {
      float: none;
      width: 100%;
      margin-right: 0;

      .description {
        font-size: $slight;
      }
    }

    &.webform-component-checkboxes {
      clear: both;
      float: none;
      width: 100%;
      padding-top: 66px;
      margin-right: 0;

      .icheckbox_square-yellow {
        @include sprite($sprite-unchecked-yellow);
      }

      .icheckbox_square-yellow.hover {
        @include sprite($sprite-unchecked-yellow);
      }

      .icheckbox_square-yellow.checked {
        @include sprite($sprite-checked-yellow);
      }

      .form-type-checkbox {
        float: none;
        width: 100%;
        margin-right: 0;
        text-align: center;
      }

      > div {
        border-radius: 50%;
      }

      .option {
        display: inline-block;
        margin-left: 7px;
      }
    }
  }

  .form-managed-file {
    @include clearfix;

    input[type="file"] {
      background-color: transparent;
      padding: 0;
    }
  }

  .webform-component-textarea {
    float: none;
    clear: both;
    width: 100%;
    margin-right: 0;
  }

  .modal-title {
    font-weight: 700;
    font-size: $bigger;
    margin-bottom: $sect-marg;
  }

  .form-item {
    text-align: left;

    label {
      font-size: $slight;
      font-weight: 400;
      margin-bottom: 5px;
      display: block;
    }
  }

  input, textarea {
    background-color: $form;
    font-family: $mada;
    border: none;
    font-size: $slight;
    padding: 10px;
    max-width: 100%;
  }

  .webform-component.webform-component--continue-to-step,
  input[type="submit"] {
    display: block;
    margin: 0 auto;
    float: none;
    padding: 10px;
    text-align: center;
    height: 45px;
    padding: 0 20px;
    min-width: 270px;
    margin-top: 20px;
    background-color: $yellow;
    font-size: $slight;
    text-decoration: none;
    color: $black;
    font-weight: 400;
    line-height: 45px;
    vertical-align: middle;

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }

  .webform-component--continue-to-step {
    position: relative;

    .throbber {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }

    .form-actions {
    input[type="submit"] {
      display: none;
    }
  }

  .webform-component-fieldset {
    @include clearfix;
  }

  .cs-modal-header {
    text-align: right;
    margin: 0 auto;
    margin-bottom: 10px;
    line-height: 0;
  }

  .close-formmodal {
    display: inline-block;

    @include links($black, $black, none, 400);

    &-btn {
      display: inline-block;

      .text {
        font-size: $slight;
        position: relative;
        top: -15px;
        margin-right: 22px;
      }

      .icon {
        position: relative;
        right: 0;
        display: inline-block;
        width: 44px;
        height: 44px;
        overflow: hidden;
        border: 2px solid $black;

        &:hover {
          &:before {
          }

          &:after {
          }
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          height: 2px;
          width: 20px;
          margin-left: -10px;
          left: 50%;
          top: 50%;
          margin-top: -1px;
          background: $black;
        }

        &:before {
          content: "";
          transform: rotate(45deg);
        }

        &:after {
          content: "";
          transform: rotate(-45deg);
        }
      }
    }
  }

  text-align: center;
  background: rgba(255, 255, 255, 1);
  box-shadow: none;
}

.node-nextprev-nav {
  margin: $reg-marg 0 0;
  @include clearfix;

  // todo
//  overflow: hidden;
//  max-height: 450px;

  .pr-item {
      &:hover,
      &:focus {
        span {
          &:before {
            animation: shakeprev 1s ease-in-out infinite;
          }
        }
      }
  }

  .nx-item {
    &:hover,
    &:focus {
      span {
        &:after {
          animation: shakenext 1s ease-in-out infinite;
        }
      }
    }
  }

  img {
    display: block;
  }

  .prev-node,
  .next-node {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: $huge;
    font-weight: 700;
    z-index: 10;
  }

  span.prev-node {
    min-width: 420px;

      &:before {
        position: relative;
        margin-right: 42px;
        left: 0;
        content: "";
        vertical-align: middle;
        display: inline-block;
        @include sprite($sprite-big-arrow);
      }
  }

  span.next-node {
      &:after {
        position: relative;
        margin-left: 42px;
        content: "";
        left: 0;
        vertical-align: middle;
        transform: rotate(-180deg);
        display: inline-block;
        @include sprite($sprite-big-arrow);
      }
  }

  .node-nextprev {
    width: span(6);
    float: left;
    margin-right: gutter();

    a {
      line-height: 1.1;
      color: $white;
    }

    a > div {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.7);
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.wide-banner-link {
  max-width: $container;
  margin: 0 auto $reg-marg auto;
  display: block;

  .field--name-field-link a {
    color: $white;
    text-decoration: none;
  }
}

.wide-banner {
  min-height: 350px;
  position: relative;
  background-attachment: static;
  background-size: cover;
  background-position: 50% 50%;

  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.8);
    top: 0;
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: statcol(4);
    width: 100%;
    transform: translate(-50%,-50%);
    color: $white;
    text-align: center;
    z-index: 10;
  }

  &__motto {
    font-size: $larger;
  }

  &__link {
    margin-top: 40px;
  }

  &__icon {
    display: inline-block;
    margin-bottom: 2px;
  }

  &--youtube {
    &__icon {
      @include sprite($sprite-youtube-banner);
    }
  }
}

.webform-confirmation {
  text-align: center;
  min-height: 500px;
  position: relative;

  .webconf {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
  }

  .tickbox {
    @include sprite($sprite-tickbox);
    margin: 0 auto;
  }

  .webform-success-txt {
    margin-top: 40px;
  }
}