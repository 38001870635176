/* scss generating background grids

.grid {
  background: susy-svg-grid() no-repeat scroll;
}*/
/*
 * Common weight name mapping
 *

400	Normal
500	Medium
600	Semi Bold (Demi Bold)
700	Bold

*/
@import url("https://fonts.googleapis.com/css?family=Mada:400,500,700");
.tabs a {
  background-color: #f2de0d;
  color: #000000;
  font-size: 0.72727rem; }
  .tabs a.active, .tabs a:hover, .tabs a:focus {
    background-color: #f2de0d;
    color: #000000; }

p {
  margin-bottom: 2em; }
  p:last-child {
    margin-bottom: 0; }

textarea,
input,
button,
a {
  outline: none; }

.l-content .node ul,
.l-content .block ul {
  list-style: disc;
  padding-left: 1em;
  margin-left: 0;
  margin-bottom: 2em; }
  .l-content .node ul li,
  .l-content .block ul li {
    margin-bottom: 0.1em; }
    .l-content .node ul li:last-child,
    .l-content .block ul li:last-child {
      margin-bottom: 0; }

.l-content .node ol,
.l-content .block ol {
  list-style: decimal;
  padding-left: 1em;
  margin-left: 0;
  margin-bottom: 2em; }
  .l-content .node ol li,
  .l-content .block ol li {
    margin-bottom: 0.1em; }
    .l-content .node ol li:last-child,
    .l-content .block ol li:last-child {
      margin-bottom: 0; }

.menu {
  list-style: none;
  list-style-image: none; }
  .menu .leaf {
    list-style: none;
    list-style-image: none; }

strong {
  font-weight: 700; }

u {
  text-decoration: line-through; }

em {
  font-style: italic; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slicknav_btn {
  position: relative;
  display: block;
  vertical-align: middle;
  float: right;
  padding: 0.438em 0.625em 0.438em 0.625em;
  line-height: 1.125em;
  cursor: pointer; }
  .slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
    margin-top: 0.188em; }

.slicknav_menu {
  *zoom: 1; }
  .slicknav_menu .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left; }
  .slicknav_menu .slicknav_icon {
    float: left;
    width: 1.125em;
    height: 0.875em;
    margin: 0.188em 0 0 0.438em; }
    .slicknav_menu .slicknav_icon:before {
      background: transparent;
      width: 1.125em;
      height: 0.875em;
      display: block;
      content: "";
      position: absolute; }
  .slicknav_menu .slicknav_no-text {
    margin: 0; }
  .slicknav_menu .slicknav_icon-bar {
    display: block;
    width: 1.125em;
    height: 0.125em;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25); }
  .slicknav_menu:before {
    content: " ";
    display: table; }
  .slicknav_menu:after {
    content: " ";
    display: table;
    clear: both; }

.slicknav_nav {
  clear: both; }
  .slicknav_nav ul {
    display: block; }
  .slicknav_nav li {
    display: block; }
  .slicknav_nav .slicknav_arrow {
    font-size: 0.8em;
    margin: 0 0 0 0.4em; }
  .slicknav_nav .slicknav_item {
    cursor: pointer; }
    .slicknav_nav .slicknav_item a {
      display: inline; }
  .slicknav_nav .slicknav_row {
    display: block; }
  .slicknav_nav a {
    display: block; }
  .slicknav_nav .slicknav_parent-link a {
    display: inline; }

.slicknav_brand {
  float: left; }

.slicknav_menu .slicknav_nav {
  padding-top: 20px; }

.slicknav_open .slicknav_menutxt:after {
  display: inline-block;
  content: "";
  background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
  background-position: -314px -245px;
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px; }

.slicknav_collapsed .slicknav_menutxt:after {
  display: inline-block;
  content: "";
  background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
  background-position: -275px -245px;
  width: 18px;
  height: 17px;
  position: relative;
  top: 2px; }

.slicknav_menu {
  clear: both;
  background: #FFFFFF; }
  .slicknav_menu a {
    color: #000000;
    text-decoration: none; }
  .slicknav_menu .slicknav_btn {
    position: static;
    display: block;
    vertical-align: middle;
    float: none;
    padding: 0;
    line-height: 0;
    cursor: pointer; }
    .slicknav_menu .slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
      margin-top: 0; }
    .slicknav_menu .slicknav_btn .slicknav_icon {
      float: none;
      display: none; }
  .slicknav_menu .slicknav_nav {
    /* margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;*/
    height: calc(100vh - 55px);
    overflow-y: auto;
    position: relative; }
    .slicknav_menu .slicknav_nav .activew-circle {
      right: 50%;
      top: 18px;
      transform: translate(58px); }
    .slicknav_menu .slicknav_nav .slicknav_arrow {
      color: #000000;
      margin-left: 0.1em;
      font-size: 16px; }
    .slicknav_menu .slicknav_nav li {
      text-align: center; }
      .slicknav_menu .slicknav_nav li a, .slicknav_menu .slicknav_nav li span.nolink {
        display: inline-block;
        color: #000000;
        position: relative;
        padding: 12px 0;
        font-size: 1.11111rem;
        text-align: center;
        font-weight: 400; }
      .slicknav_menu .slicknav_nav li.slicknav_parent > a {
        padding-left: 0; }
      .slicknav_menu .slicknav_nav li .slicknav_arrow {
        position: absolute;
        top: 0;
        right: 0;
        height: 36px;
        width: 56px;
        display: block;
        line-height: 36px;
        vertical-align: middle;
        text-align: center; }
  .slicknav_menu .slicknav_nav .sf-hidden li a {
    padding-left: 30px; }
  .slicknav_menu .slicknav_menutxt {
    display: block;
    float: none;
    font-size: 0.88888rem;
    /*     margin-right: 0.8em;
        margin-bottom: -0.2em; */
    vertical-align: text-bottom;
    padding: 16px 0 16px 18px;
    width: 90px;
    text-align: right;
    line-height: 1.4;
    background: none;
    color: #000000;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0px; }
    .slicknav_menu .slicknav_menutxt .txt-layer {
      position: relative;
      top: 0;
      margin-right: 10px; }

.selectboxit-text {
  padding-right: 5px; }

.selectboxit-container {
  width: 100%;
  max-width: 100%; }
  .selectboxit-container .selectboxit-option-anchor {
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    display: block; }
  .selectboxit-container * {
    font-family: "Mada", sans-serif, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    white-space: nowrap; }
  .selectboxit-container .selectboxit-list {
    background-color: #efefef;
    border: none; }
  .selectboxit-container .selectboxit-options {
    border-radius: 0;
    max-width: 100%;
    /* Set's the drop down options width to the same width as the drop down button */
    /* Set's the max-height property to only show a subset of the drop down items.
       If you do not set a max-height property, SelectBoxIt will dynamically
       position the dropdown (when opened) to make sure the drop down items are not
       displayed outside of the current window viewport.
    */
    max-height: 260px; }
    .selectboxit-container .selectboxit-options .selectboxit-option-anchor {
      border-radius: 0; }
  .selectboxit-container span, .selectboxit-container .selectboxit-options a {
    border-radius: 0;
    text-align: left;
    height: 45px;
    border: none;
    line-height: 45px;
    display: block;
    color: black;
    transition: none;
    font-family: "Mada", sans-serif, sans-serif;
    font-weight: 400;
    font-size: 0.81818rem; }
  .selectboxit-container .selectboxit-text {
    max-width: 88% !important; }
  .selectboxit-container .selectboxit {
    width: 100%;
    border-radius: 0;
    background: #efefef;
    border: none;
    float: none;
    height: 45px; }
    .selectboxit-container .selectboxit:hover, .selectboxit-container .selectboxit:focus {
      color: black;
      background: #efefef; }

.selectboxit .selectboxit-arrow-container .selectboxit-arrow {
  margin-top: -2px; }

.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  color: #000000;
  background: #f2de0d; }

.selectboxit-default-arrow {
  width: 0;
  height: 0;
  border-top: 6px solid #000000;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent; }

@keyframes pulse {
  to {
    transform: scale(1.1); } }

@keyframes shakeprev {
  50% {
    left: 12px; }
  100% {
    left: 0; } }

@keyframes shakenext {
  50% {
    left: -12px; }
  100% {
    left: 0; } }

/*
@keyframes draw {
  to {stroke-dashoffset: 0;}
}

@keyframes fillin {
  to {fill-opacity: 1; stroke-opacity: 0}
}*/
.field--name-field-page-main-section-title,
.field--name-field-page-section-title,
.project-boxes__title {
  font-size: 2.54545rem;
  font-weight: 700;
  line-height: 1.1;
  max-width: 920px;
  margin-bottom: 50px; }

.field--name-field-faq {
  max-width: 920px; }

.field--name-field-page-section-body,
.group-below-optionals {
  font-size: 1.63636rem;
  font-weight: 400;
  line-height: 1.44;
  max-width: 920px; }

.field--name-field-project-credits {
  font-size: 1.18181rem;
  font-weight: 400;
  line-height: 1.44; }

.block--gtweaks-artists-join-btn.block.block--gtweaks {
  margin-bottom: 0; }

ul.pager {
  margin: 100px 0 0 0; }
  ul.pager li a:link, ul.pager li a:visited, ul.pager li a:active {
    text-decoration: none;
    color: #000000;
    font-weight: 400; }
  ul.pager li a:hover, ul.pager li a:focus {
    color: #000000;
    text-decoration: none; }
  ul.pager li.pager__item--current {
    font-weight: 400;
    padding: 0;
    display: inline-block;
    width: 38px;
    height: 38px;
    line-height: 38px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    background-color: #f2de0d; }
  ul.pager li a {
    display: inline-block;
    width: 38px;
    height: 38px;
    line-height: 38px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle; }
    ul.pager li a:hover, ul.pager li a:focus {
      background-color: #f2de0d; }

.read-more {
  display: block;
  padding: 10px;
  text-align: center;
  width: 180px;
  height: 50px;
  background-color: #f2de0d;
  font-size: 0.81818rem;
  text-decoration: none;
  color: #000000;
  font-weight: 400;
  line-height: 30px;
  vertical-align: middle;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.18); }
  .read-more-arrow {
    box-shadow: none;
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 400;
    padding: 0;
    margin-top: 50px;
    background-color: transparent;
    vertical-align: middle;
    line-height: 50px;
    width: auto;
    float: left; }
    .read-more-arrow:after {
      margin-left: 18px;
      content: "";
      line-height: 50px;
      vertical-align: middle;
      display: inline-block;
      background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
      background-position: -317px -194px;
      width: 22px;
      height: 14px; }
  .read-more:hover, .read-more:focus {
    background-color: #000000;
    color: #FFFFFF; }

.parallax-full {
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  min-height: 960px;
  position: relative;
  background-repeat: no-repeat;
  z-index: 1; }
  .parallax-full:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.6); }
  .parallax-full__title {
    /*    top: 50%;
    right: 50%;
    transform: translate(-50%,-50%);*/
    transform: translate(50%, 50%);
    bottom: 50%;
    right: 50%;
    position: absolute;
    font-weight: 700;
    line-height: 1.18;
    color: #FFFFFF;
    font-size: 2.54545rem;
    text-align: center;
    width: 100%;
    max-width: 1056px;
    margin: 0 auto;
    padding: 80px 0;
    z-index: 10; }
    .parallax-full__title:before {
      content: "";
      background-color: #FFFFFF;
      height: 1px;
      width: 74.24242%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%); }
    .parallax-full__title:after {
      content: "";
      background-color: #FFFFFF;
      height: 1px;
      position: absolute;
      width: 74.24242%;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); }

.field--name-field-page-section .field-group-html-element.group-text-section {
  margin-bottom: 150px; }

.field--name-field-page-section .field-group-html-element.group-image-section {
  margin-bottom: 150px; }

.field--name-field-page-section .field__items .field__item:last-child .field-collection-view {
  margin-bottom: 0; }

.vidplay {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 242px;
  height: 242px; }
  .vidplay img {
    width: auto;
    height: 40px;
    left: 50%;
    top: 50%;
    margin-top: -20px;
    margin-left: -13px;
    position: absolute; }
  .vidplay--teaser:before, .vidplay--full:before, .vidplay--featured:before {
    content: "";
    display: block;
    width: 136px;
    height: 136px;
    border-radius: 50%;
    background-color: #FFFFFF;
    opacity: 0.16;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .vidplay--teaser:after, .vidplay--full:after, .vidplay--featured:after {
    content: "";
    display: block;
    width: 242px;
    position: absolute;
    height: 242px;
    border-radius: 50%;
    background-color: #FFFFFF;
    opacity: 0.09;
    left: 0;
    top: 0;
    animation: pulse 2s ease-in-out infinite alternate; }

.trigger-modalvid:hover, .trigger-modalvid:focus,
.trigger-replacevid:hover,
.trigger-replacevid:focus {
  cursor: pointer; }

.embedded-video .player,
.node-teaser .field--type-soundcloud .field__item,
.node-nextprev .field--type-soundcloud .field__item {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden; }

.project-duo-line .node-teaser .field--type-soundcloud .field__item,
.node-nextprev .field--type-soundcloud .field__item {
  padding-bottom: 57.45%; }

.project-duo-line .node-teaser .field--type-soundcloud .field__item iframe,
.node-nextprev .field--type-soundcloud .field__item iframe {
  max-height: 450px; }

.embedded-video .player iframe,
.node-teaser .field--type-soundcloud .field__item iframe,
.node-nextprev .field--type-soundcloud .field__item iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

#vidmodal {
  text-align: center;
  background: transparent;
  box-shadow: none; }
  #vidmodal .cs-modal-header {
    max-width: 58vw;
    text-align: right;
    margin: 0 auto;
    margin-bottom: 30px;
    line-height: 0; }
  #vidmodal .close-vidmodal-btn {
    position: relative;
    right: -80px;
    display: inline-block;
    width: 52px;
    height: 52px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #000000; }
    #vidmodal .close-vidmodal-btn:before, #vidmodal .close-vidmodal-btn:after {
      content: '';
      transition: transform 0.3s ease-out;
      position: absolute;
      height: 3px;
      width: 22px;
      margin-left: -11px;
      left: 50%;
      top: 50%;
      margin-top: -1px;
      background: #000000; }
    #vidmodal .close-vidmodal-btn:before {
      content: "";
      transform: rotate(45deg); }
    #vidmodal .close-vidmodal-btn:after {
      content: "";
      transform: rotate(-45deg); }
  #vidmodal .embedded-video {
    display: inline-block;
    width: 100%;
    height: 100%;
    max-width: 58vw; }
  #vidmodal .player {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    overflow: hidden;
    height: 0; }
    #vidmodal .player iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.project-boxes {
  max-width: 1328px; }
  .project-boxes:after {
    content: "";
    display: table;
    clear: both; }

.project-box {
  text-align: center;
  float: left;
  margin-right: 2.40964%;
  width: 18.07229%; }
  .project-box:nth-child(5n) {
    margin-right: 0;
    margin-bottom: 100px; }
  .project-box:nth-child(5n+1) {
    clear: both; }
  .project-box:last-child {
    margin-bottom: 0; }
  .project-box__icon {
    margin-bottom: 10px;
    /*
    svg {
      height: 60px;
      width: auto;

      path {
        fill-opacity: 0;
        stroke-width: 0.2;
        stroke: $black;
        stroke-miterlimit: 10;
      }
    }

    &.animatesvg svg path {
      animation: draw 2s 1s linear forwards, fillin 2s 3s linear forwards;
    }*/ }
    .project-box__icon img {
      height: 60px;
      width: auto; }

.back-link-wrap {
  margin-top: -160px;
  margin-bottom: 160px; }
  .back-link-wrap a:link, .back-link-wrap a:visited, .back-link-wrap a:active {
    text-decoration: none;
    color: #000000;
    font-weight: 400; }
  .back-link-wrap a:hover, .back-link-wrap a:focus {
    color: #000000;
    text-decoration: none; }
  .back-link-wrap .back-link {
    font-size: 0.81818rem; }
    .back-link-wrap .back-link:before {
      margin-right: 18px;
      content: "";
      vertical-align: middle;
      transform: rotate(-180deg);
      display: inline-block;
      background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
      background-position: -341px -194px;
      width: 22px;
      height: 14px; }

.iziModal.hasShadow:after {
  opacity: 0; }

.marker,
.form-required {
  color: #ff1414; }

#formmodal {
  /* Overrides /modules/system/system.base.css with a custom throbber. */
  /* these apply to all ajax progresses */
  text-align: center;
  background: white;
  box-shadow: none; }
  #formmodal .ajax-progress.custom-prog {
    display: block;
    *zoom: 1; }
  #formmodal .ajax-progress.custom-prog .throbber {
    background: transparent url("../images/ajax-throbber.gif") no-repeat 0px 0px;
    float: none;
    height: 26px;
    /* tweak this according to your gif */
    width: 26px;
    /* tweak this according to your gif */
    margin: 0; }
  #formmodal .remove-button-wrapper .ajax-progress {
    display: none; }
  #formmodal .webform-component--upload_your_files {
    margin-top: 20px; }
  #formmodal ul.file-widget li {
    font-size: 0.81818rem;
    margin-bottom: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid #f0f0f0; }
    #formmodal ul.file-widget li:after {
      content: "";
      display: table;
      clear: both; }
    #formmodal ul.file-widget li .file img {
      display: block;
      margin-right: 8px;
      float: left; }
    #formmodal ul.file-widget li .file a {
      display: block;
      overflow: hidden;
      text-align: left;
      word-break: break-all; }
  #formmodal ul.file-widget .form-managed-file {
    float: left;
    width: 75%;
    margin-right: 7%; }
  #formmodal .file-uploaded-browser-wrapper {
    display: block;
    font-size: 0.81818rem;
    margin-bottom: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid #f0f0f0; }
    #formmodal .file-uploaded-browser-wrapper div {
      display: block; }
    #formmodal .file-uploaded-browser-wrapper:after {
      content: "";
      display: table;
      clear: both; }
    #formmodal .file-uploaded-browser-wrapper .file {
      display: block;
      float: left;
      width: 75%;
      margin-right: 7%;
      text-align: left; }
      #formmodal .file-uploaded-browser-wrapper .file img {
        display: block;
        margin-right: 8px;
        float: left; }
      #formmodal .file-uploaded-browser-wrapper .file a {
        display: block;
        overflow: hidden;
        text-align: left;
        word-break: break-all; }
  #formmodal .remove-button-wrapper {
    float: right;
    width: 18%;
    text-align: right; }
    #formmodal .remove-button-wrapper input[type="submit"] {
      font-size: 0.63636rem;
      display: block;
      margin-top: 0px;
      padding: 4px 32px 4px 0;
      background: url("../images/delete-file.png") right center no-repeat;
      text-align: center;
      height: auto;
      min-width: 0;
      background-color: transparent;
      text-decoration: none;
      line-height: 1.4;
      vertical-align: baseline; }
  #formmodal .file-original-browse-wrapper-inner {
    overflow: hidden;
    display: block; }
  #formmodal .file-original-browse-wrapper {
    float: left;
    width: 55%;
    margin-right: 8%;
    font-size: 0.81818rem; }
    #formmodal .file-original-browse-wrapper:before {
      content: "";
      background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
      background-position: -92px -280px;
      width: 27px;
      height: 24px;
      display: block;
      float: left;
      margin-right: 10px;
      margin-top: 18px; }
  #formmodal .file-custom-browse-wrapper {
    float: right;
    width: 37%;
    font-size: 0.81818rem;
    display: block;
    position: relative; }
    #formmodal .file-custom-browse-wrapper .throbber {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%); }
  #formmodal .file-custom-browse {
    border: 1px solid #000000;
    padding: 8px 14px;
    width: 100%;
    text-align: center;
    display: block; }
    #formmodal .file-custom-browse:hover, #formmodal .file-custom-browse:focus {
      background-color: #000000;
      color: #FFFFFF;
      cursor: pointer; }
  #formmodal .form-wrapper .fieldset-legend {
    font-size: 0.81818rem; }
  #formmodal .form-item {
    position: relative; }
    #formmodal .form-item.webform-component-file, #formmodal .form-item.webform-component-multiple-file {
      float: none;
      margin-bottom: 50px;
      width: 100%;
      margin-right: 0; }
      #formmodal .form-item.webform-component-file:after, #formmodal .form-item.webform-component-multiple-file:after {
        content: "";
        display: table;
        clear: both; }
      #formmodal .form-item.webform-component-file div.description, #formmodal .form-item.webform-component-multiple-file div.description {
        font-size: 0.63636rem; }
  #formmodal .webform-component-fieldset {
    display: none;
    min-height: 360px; }
    #formmodal .webform-component-fieldset:first-child {
      display: block; }
  #formmodal .messages {
    font-size: 0.81818rem;
    padding: 6px;
    margin: 0 0 20px 0; }
  #formmodal .modal-form-content {
    max-width: 780px;
    margin: 0 auto; }
  #formmodal .node--webform {
    max-width: 580px; }
    #formmodal .node--webform:after {
      content: "";
      display: table;
      clear: both; }
  #formmodal .form-item {
    float: left;
    margin-bottom: 20px;
    width: 48%;
    margin-right: 4%; }
    #formmodal .form-item:nth-child(2n) {
      margin-right: 0; }
    #formmodal .form-item:nth-child(2n+1) {
      clear: both; }
    #formmodal .form-item.webform-component-file {
      float: none;
      width: 100%;
      margin-right: 0; }
      #formmodal .form-item.webform-component-file .description {
        font-size: 0.81818rem; }
    #formmodal .form-item.webform-component-checkboxes {
      clear: both;
      float: none;
      width: 100%;
      padding-top: 66px;
      margin-right: 0; }
      #formmodal .form-item.webform-component-checkboxes .icheckbox_square-yellow {
        background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
        background-position: -62px -280px;
        width: 28px;
        height: 28px; }
      #formmodal .form-item.webform-component-checkboxes .icheckbox_square-yellow.hover {
        background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
        background-position: -62px -280px;
        width: 28px;
        height: 28px; }
      #formmodal .form-item.webform-component-checkboxes .icheckbox_square-yellow.checked {
        background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
        background-position: -32px -280px;
        width: 28px;
        height: 28px; }
      #formmodal .form-item.webform-component-checkboxes .form-type-checkbox {
        float: none;
        width: 100%;
        margin-right: 0;
        text-align: center; }
      #formmodal .form-item.webform-component-checkboxes > div {
        border-radius: 50%; }
      #formmodal .form-item.webform-component-checkboxes .option {
        display: inline-block;
        margin-left: 7px; }
  #formmodal .form-managed-file:after {
    content: "";
    display: table;
    clear: both; }
  #formmodal .form-managed-file input[type="file"] {
    background-color: transparent;
    padding: 0; }
  #formmodal .webform-component-textarea {
    float: none;
    clear: both;
    width: 100%;
    margin-right: 0; }
  #formmodal .modal-title {
    font-weight: 700;
    font-size: 1.18181rem;
    margin-bottom: 50px; }
  #formmodal .form-item {
    text-align: left; }
    #formmodal .form-item label {
      font-size: 0.81818rem;
      font-weight: 400;
      margin-bottom: 5px;
      display: block; }
  #formmodal input, #formmodal textarea {
    background-color: #efefef;
    font-family: "Mada", sans-serif;
    border: none;
    font-size: 0.81818rem;
    padding: 10px;
    max-width: 100%; }
  #formmodal .webform-component.webform-component--continue-to-step,
  #formmodal input[type="submit"] {
    display: block;
    margin: 0 auto;
    float: none;
    padding: 10px;
    text-align: center;
    height: 45px;
    padding: 0 20px;
    min-width: 270px;
    margin-top: 20px;
    background-color: #f2de0d;
    font-size: 0.81818rem;
    text-decoration: none;
    color: #000000;
    font-weight: 400;
    line-height: 45px;
    vertical-align: middle; }
    #formmodal .webform-component.webform-component--continue-to-step:hover, #formmodal .webform-component.webform-component--continue-to-step:focus,
    #formmodal input[type="submit"]:hover,
    #formmodal input[type="submit"]:focus {
      cursor: pointer; }
  #formmodal .webform-component--continue-to-step {
    position: relative; }
    #formmodal .webform-component--continue-to-step .throbber {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%); }
  #formmodal .form-actions input[type="submit"] {
    display: none; }
  #formmodal .webform-component-fieldset:after {
    content: "";
    display: table;
    clear: both; }
  #formmodal .cs-modal-header {
    text-align: right;
    margin: 0 auto;
    margin-bottom: 10px;
    line-height: 0; }
  #formmodal .close-formmodal {
    display: inline-block; }
    #formmodal .close-formmodal a:link, #formmodal .close-formmodal a:visited, #formmodal .close-formmodal a:active {
      text-decoration: none;
      color: #000000;
      font-weight: 400; }
    #formmodal .close-formmodal a:hover, #formmodal .close-formmodal a:focus {
      color: #000000;
      text-decoration: none; }
    #formmodal .close-formmodal-btn {
      display: inline-block; }
      #formmodal .close-formmodal-btn .text {
        font-size: 0.81818rem;
        position: relative;
        top: -15px;
        margin-right: 22px; }
      #formmodal .close-formmodal-btn .icon {
        position: relative;
        right: 0;
        display: inline-block;
        width: 44px;
        height: 44px;
        overflow: hidden;
        border: 2px solid #000000; }
        #formmodal .close-formmodal-btn .icon:before, #formmodal .close-formmodal-btn .icon:after {
          content: '';
          position: absolute;
          height: 2px;
          width: 20px;
          margin-left: -10px;
          left: 50%;
          top: 50%;
          margin-top: -1px;
          background: #000000; }
        #formmodal .close-formmodal-btn .icon:before {
          content: "";
          transform: rotate(45deg); }
        #formmodal .close-formmodal-btn .icon:after {
          content: "";
          transform: rotate(-45deg); }

.node-nextprev-nav {
  margin: 150px 0 0; }
  .node-nextprev-nav:after {
    content: "";
    display: table;
    clear: both; }
  .node-nextprev-nav .pr-item:hover span:before, .node-nextprev-nav .pr-item:focus span:before {
    animation: shakeprev 1s ease-in-out infinite; }
  .node-nextprev-nav .nx-item:hover span:after, .node-nextprev-nav .nx-item:focus span:after {
    animation: shakenext 1s ease-in-out infinite; }
  .node-nextprev-nav img {
    display: block; }
  .node-nextprev-nav .prev-node,
  .node-nextprev-nav .next-node {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.54545rem;
    font-weight: 700;
    z-index: 10; }
  .node-nextprev-nav span.prev-node {
    min-width: 420px; }
    .node-nextprev-nav span.prev-node:before {
      position: relative;
      margin-right: 42px;
      left: 0;
      content: "";
      vertical-align: middle;
      display: inline-block;
      background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
      background-position: -110px -245px;
      width: 31px;
      height: 21px; }
  .node-nextprev-nav span.next-node:after {
    position: relative;
    margin-left: 42px;
    content: "";
    left: 0;
    vertical-align: middle;
    transform: rotate(-180deg);
    display: inline-block;
    background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
    background-position: -110px -245px;
    width: 31px;
    height: 21px; }
  .node-nextprev-nav .node-nextprev {
    width: 49%;
    float: left;
    margin-right: 2%; }
    .node-nextprev-nav .node-nextprev a {
      line-height: 1.1;
      color: #FFFFFF; }
    .node-nextprev-nav .node-nextprev a > div {
      position: relative; }
      .node-nextprev-nav .node-nextprev a > div:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7); }
    .node-nextprev-nav .node-nextprev:last-child {
      margin-right: 0; }

.wide-banner-link {
  max-width: 1600px;
  margin: 0 auto 150px auto;
  display: block; }
  .wide-banner-link .field--name-field-link a {
    color: #FFFFFF;
    text-decoration: none; }

.wide-banner {
  min-height: 350px;
  position: relative;
  background-attachment: static;
  background-size: cover;
  background-position: 50% 50%; }
  .wide-banner:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0; }
  .wide-banner__text {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 512px;
    width: 100%;
    transform: translate(-50%, -50%);
    color: #FFFFFF;
    text-align: center;
    z-index: 10; }
  .wide-banner__motto {
    font-size: 1.63636rem; }
  .wide-banner__link {
    margin-top: 40px; }
  .wide-banner__icon {
    display: inline-block;
    margin-bottom: 2px; }
  .wide-banner--youtube__icon {
    background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
    background-position: -226px -162px;
    width: 66px;
    height: 50px; }

.webform-confirmation {
  text-align: center;
  min-height: 500px;
  position: relative; }
  .webform-confirmation .webconf {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%); }
  .webform-confirmation .tickbox {
    background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
    background-position: 0px 0px;
    width: 224px;
    height: 224px;
    margin: 0 auto; }
  .webform-confirmation .webform-success-txt {
    margin-top: 40px; }

img {
  height: auto;
  max-width: 100%; }

html {
  font-size: 22px;
  line-height: 1.4;
  font-family: "Mada", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

.slicknav_menu {
  display: none; }

.logged-in #block-views-footer-region-switcher-block {
  position: static; }

body {
  line-height: inherit;
  color: #000000;
  padding-top: 70px; }

.below-sect-wrapper {
  transition: all 1s ease-out 0.8s;
  opacity: 0;
  top: 0; }
  .below-sect-wrapper .below-banner-title {
    position: relative;
    padding-bottom: 8px;
    display: inline-block; }
    .below-sect-wrapper .below-banner-title:after {
      content: "";
      transition: transform 1s ease-out 0.8s;
      transform-origin: 0 0;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #f2de0d;
      transform: scaleX(0);
      width: 100%;
      height: 5px; }
  .below-sect-wrapper.anim-loaded {
    top: -80px;
    opacity: 1; }
    .below-sect-wrapper.anim-loaded .below-banner-title:after {
      transform: scaleX(1); }

.front .l-header-wrapper {
  transition: all 1s ease-out 0.8s;
  transform: translateY(-100%);
  opacity: 0; }
  .front .l-header-wrapper.anim-loaded {
    transform: translateY(0);
    opacity: 1; }

.messages {
  margin-bottom: 20px;
  max-width: 1600px;
  margin: 0 auto; }

/*body {
  &.front {
    padding-top: 0;
    transition: padding 0.5s ease-out 2.3s;

    &.anim-loaded {
      padding-top: 70px;
    }
  }
}*/
.vcurtain {
  position: absolute;
  height: 100%;
  width: 25%;
  background-color: #FFFFFF;
  top: 0;
  z-index: 100;
  transition: all 0.8s ease-out; }
  .vcurtain-1 {
    left: 0; }
  .vcurtain-2 {
    left: 25%; }
  .vcurtain-3 {
    left: 50%; }
  .vcurtain-4 {
    left: 75%; }
  .vcurtain.anim-loaded {
    transform: scaleX(0); }

.top-sect {
  margin-bottom: 0; }

#how-we-do-it:before {
  content: "";
  display: block;
  height: 90px;
  margin-top: -90px;
  visibility: hidden; }

.view-offices .inner-row:before {
  content: "";
  visibility: hidden;
  margin-top: -90px;
  height: 90px;
  display: block; }

.view-offices .views-row {
  margin-bottom: 200px;
  transition: all 0.6s ease-out; }
  .view-offices .views-row:after {
    content: "";
    display: table;
    clear: both; }
  .view-offices .views-row-odd .group-office-image {
    opacity: 0;
    transform: translateX(-5%); }
  .view-offices .views-row-even .group-office-image {
    opacity: 0;
    transform: translateX(5%); }
  .view-offices .views-row .group-office-image {
    transition: all 0.6s ease-out; }
    .view-offices .views-row .group-office-image.anim-loaded {
      opacity: 1;
      transform: translateX(0); }
  .view-offices .views-row .group-office-info {
    transition: all 0.6s ease-out;
    opacity: 0;
    transform: translateY(10%); }
    .view-offices .views-row .group-office-info.anim-loaded {
      opacity: 1;
      transform: translateY(0); }
  .view-offices .views-row:last-child .inner-row {
    margin-bottom: 0; }
  .view-offices .views-row .group-office-image {
    width: 66%; }
  .view-offices .views-row group-office-info {
    width: 23.5%; }
  .view-offices .views-row-odd .group-office-image {
    float: left;
    margin-right: 2%; }
  .view-offices .views-row-odd .group-office-info {
    width: 23.5%;
    float: left; }
  .view-offices .views-row-even .group-office-image {
    float: right; }
  .view-offices .views-row-even .group-office-info {
    width: 23.5%;
    float: left;
    margin-left: 8.5%; }

.last-sect {
  max-width: 1600px;
  margin: 0 auto;
  margin-bottom: 50px; }
  .last-sect .read-more {
    margin-top: 40px; }
  .last-sect-title {
    font-size: 2.54545rem;
    font-weight: 700;
    line-height: 1.1;
    max-width: 920px; }

.below-sect-wrapper {
  max-width: 1664px;
  margin: 0 auto;
  padding: 32px 32px 0 32px;
  background-color: #FFFFFF;
  position: relative;
  z-index: 100; }
  .below-sect-wrapper:after {
    content: "";
    display: table;
    clear: both; }

.let-us-know-wrapper {
  margin-top: 150px; }
  .let-us-know-wrapper .read-more-wrapper {
    margin-top: 42px; }

.below-sect {
  text-align: center; }
  .below-sect:after {
    content: "";
    display: table;
    clear: both; }
  .below-sect .below-banner-block {
    vertical-align: top;
    text-align: left;
    max-width: 17%;
    margin-right: 10.5%;
    display: inline-block; }
    .below-sect .below-banner-block:hover .read-more {
      transform: translateX(0);
      opacity: 1; }
    .below-sect .below-banner-block .read-more {
      color: #000000;
      opacity: 0;
      margin-top: 40px;
      background: transparent;
      transform: translateX(-20%);
      transition: all 0.3s ease-out; }
      .below-sect .below-banner-block .read-more:after {
        background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
        background-position: -341px -194px;
        width: 22px;
        height: 14px; }
      .below-sect .below-banner-block .read-more:hover, .below-sect .below-banner-block .read-more:focus {
        background: transparent; }
      .below-sect .below-banner-block .read-more a {
        color: #000000; }
    .below-sect .below-banner-block:last-child {
      margin-right: 0; }
    .below-sect .below-banner-block .below-banner-title {
      font-size: 1.18181rem;
      font-weight: 700;
      margin-bottom: 12px; }
    .below-sect .below-banner-block .below-banner-content.regions-list a:link, .below-sect .below-banner-block .below-banner-content.regions-list a:visited, .below-sect .below-banner-block .below-banner-content.regions-list a:active {
      text-decoration: none;
      color: #000000;
      font-weight: 400; }
    .below-sect .below-banner-block .below-banner-content.regions-list a:hover, .below-sect .below-banner-block .below-banner-content.regions-list a:focus {
      color: #f2de0d;
      text-decoration: none; }
    .below-sect .below-banner-block .below-banner-content.regions-list a {
      display: block; }

.main-videos-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: calc(93.2vh - 80px);
  min-height: 480px;
  z-index: -1; }
  .main-videos-wrapper iframe {
    width: 100vw;
    height: 56.25vw;
    /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-width: 177.77vh;
    /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .main-videos-wrapper .titled-mega {
    position: absolute;
    z-index: 20;
    left: 50%;
    top: 50%;
    margin-top: -40px;
    transform: translate(-50%, -50%);
    color: #FFFFFF;
    width: 100%;
    max-width: 1600px; }
    .main-videos-wrapper .titled-mega .titled-title {
      font-size: 4.54545rem;
      font-weight: 700;
      line-height: 0.9;
      margin-bottom: 30px;
      max-width: 920px; }
    .main-videos-wrapper .titled-mega .titled-text {
      max-width: 920px; }
      .main-videos-wrapper .titled-mega .titled-text ul li {
        font-size: 1.36363rem;
        display: inline-block;
        margin-right: 20px; }
        .main-videos-wrapper .titled-mega .titled-text ul li:last-child {
          margin-right: 0; }
  .main-videos-wrapper:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); }

.logos-fsec {
  padding-bottom: 150px; }
  .logos-fsec .logos-title {
    font-size: 1.18181rem;
    font-weight: 700;
    margin-bottom: 50px; }
  .logos-fsec .views-field-field-images {
    float: left;
    margin-right: 4.93827%;
    width: 16.04938%; }
    .logos-fsec .views-field-field-images:nth-child(5n) {
      margin-right: 0;
      margin-bottom: 4.93827%; }
    .logos-fsec .views-field-field-images:nth-child(5n+1) {
      clear: both; }
    .logos-fsec .views-field-field-images:last-child {
      margin-bottom: 0; }
  .logos-fsec .fsec-clients {
    width: 40.5%;
    margin-right: 10.5%;
    float: left; }
  .logos-fsec .fsec-collabs {
    width: 40.5%;
    float: left; }

.l-header,
.l-content,
.l-footer,
.l-below-content,
.l-subfooter,
.front-sect {
  max-width: 1600px;
  margin: 0 auto; }
  .l-header:after,
  .l-content:after,
  .l-footer:after,
  .l-below-content:after,
  .l-subfooter:after,
  .front-sect:after {
    content: "";
    display: table;
    clear: both; }

.l-header {
  height: 70px; }
  .l-header:after {
    content: "";
    display: table;
    clear: both; }

.front .l-content {
  padding-top: 0;
  max-width: none; }

.l-subfooter {
  padding: 30px 0; }

.l-branding {
  max-width: 240px;
  float: left; }

.l-region--header {
  float: right;
  margin-left: 272px;
  position: absolute;
  top: 0;
  right: 0; }

.clientside-error li {
  color: #ff1414; }
  .clientside-error li label.error {
    color: #ff1414; }

.messages--error,
.error {
  color: #000000; }

.block--system-main-menu {
  float: right; }
  .block--system-main-menu li {
    float: left; }
  .block--system-main-menu a {
    display: block;
    padding: 24px 30px;
    font-weight: 400;
    font-size: 0.72727rem;
    text-decoration: none;
    position: relative;
    color: #000000; }
    .block--system-main-menu a:hover .overlay-title, .block--system-main-menu a:active .overlay-title {
      color: #000000; }
    .block--system-main-menu a:hover:after, .block--system-main-menu a:active:after {
      transform: scaleX(1); }
    .block--system-main-menu a:after {
      transform-origin: 0 0;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: #f2de0d;
      transform: scaleX(0);
      width: 100%;
      height: 5px;
      transition: transform 0.25s ease-in-out; }
    .block--system-main-menu a.active:after {
      transform: scaleX(1); }
  .block--system-main-menu .gtn-button a {
    color: #FFFFFF;
    background-color: #000000;
    padding: 25px 51px; }
    .block--system-main-menu .gtn-button a .overlay-title {
      position: relative;
      z-index: 50;
      color: #FFFFFF; }
    .block--system-main-menu .gtn-button a:hover:after, .block--system-main-menu .gtn-button a:focus:after, .block--system-main-menu .gtn-button a.active:after {
      background-color: #f2de0d;
      transform: scaleX(1); }

.l-header-wrapper {
  background: #FFFFFF;
  position: relative; }

.block--gtweaks-copyright {
  font-size: 0.81818rem; }

.l-footer-wrapper {
  background-image: linear-gradient(to bottom, #FFFFFF 0, #FFFFFF 90px, #f6f6f6 90px, #f6f6f6 100%);
  position: relative;
  padding-top: 90px;
  margin-top: 150px; }

.l-subfooter .cp-left {
  float: left; }

.l-subfooter .cp-right {
  float: right; }
  .l-subfooter .cp-right span {
    display: inline-block;
    vertical-align: middle; }

.l-subfooter .fl-nv {
  margin-right: 50px;
  background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
  background-position: 0px -226px;
  width: 159px;
  height: 17px; }

.l-subfooter .fl-es .fl-tx {
  float: left;
  margin-right: 20px; }

.l-subfooter .fl-es .fl-ic {
  position: relative;
  top: -3px;
  background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
  background-position: -294px -162px;
  width: 85px;
  height: 30px; }

.l-subfooter .cp-text {
  float: left;
  display: block; }

.l-subfooter .social-media-icons {
  float: left;
  margin-left: 20px; }
  .l-subfooter .social-media-icons__item {
    float: left;
    margin-right: 12px; }
    .l-subfooter .social-media-icons__item--youtube {
      background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
      background-position: -143px -245px;
      width: 26px;
      height: 20px;
      position: relative;
      top: 2px; }
    .l-subfooter .social-media-icons__item--linkedin {
      background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
      background-position: -252px -245px;
      width: 21px;
      height: 20px; }
    .l-subfooter .social-media-icons__item--vimeo {
      background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
      background-position: -171px -245px;
      width: 26px;
      height: 20px;
      position: relative;
      top: 2px; }
    .l-subfooter .social-media-icons__item--facebook {
      background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
      background-position: -199px -245px;
      width: 26px;
      height: 20px;
      position: relative;
      top: 2px; }

.file a:link, .file a:visited, .file a:active {
  text-decoration: none;
  color: #000000;
  font-weight: 400; }

.file a:hover, .file a:focus {
  color: #000000;
  text-decoration: none; }

.site-logo {
  display: block;
  margin: 10px 0; }

/* intro spacing
* Also for node type Page don't do l-main container because we have fullscreen images
*/
.node-type {
  /*  &-artist {
    .l-content {
  //    padding-top: $page-spacing;
    }
  }*/ }
  .node-type-page .l-footer-wrapper {
    margin-top: 0; }
  .node-type-page .group-text-section {
    max-width: 1600px;
    margin: 0 auto; }
  .node-type-page .group-image-section {
    position: relative; }
  .node-type-page .l-content {
    max-width: none; }

.l-content {
  padding-top: 200px; }

.page-node-1 .field--name-field-page-section .field__items .field__item:first-child .field--name-field-page-section-title {
  margin-bottom: 150px; }

.ajax-progress-throbber {
  display: none; }

.l-below-content {
  overflow: hidden; }
  .l-below-content .block {
    margin-bottom: 150px;
    /*    &:last-child {
      margin-bottom: 0;
    }*/ }

#block-views-artists-projects-block {
  margin-bottom: 0; }

.view-projects.view-display-id-page .views-exposed-widgets {
  position: relative;
  margin-bottom: 150px; }
  .view-projects.view-display-id-page .views-exposed-widgets:after {
    content: "";
    display: table;
    clear: both; }
  .view-projects.view-display-id-page .views-exposed-widgets #edit-field-project-category-tid {
    display: none; }
  .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid .selected-mopt {
    letter-spacing: 0.18em; }
  .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid .mopt {
    user-select: none;
    font-size: 2.54545rem;
    width: 376px;
    font-weight: 400;
    float: left;
    line-height: 1.1;
    position: relative;
    display: block;
    padding-right: 25px; }
    .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid .mopt:hover, .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid .mopt:focus {
      cursor: pointer; }
    .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid .mopt:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      border-top: 8px solid #000000;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent; }
    .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid .mopt.mop-selected:after {
      border-bottom: 8px solid #000000;
      border-top: none;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent; }
  .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid .list-optw {
    clear: both;
    position: relative; }
  .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid .list-menu-opt-wrapper {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    z-index: 100;
    font-size: 2.54545rem;
    font-weight: 400;
    background-color: #FFFFFF;
    padding-left: 544px;
    width: 100%;
    padding-bottom: 120px;
    padding-top: 12px; }
    .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid .list-menu-opt-wrapper li {
      padding-bottom: 4px; }
      .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid .list-menu-opt-wrapper li:hover, .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid .list-menu-opt-wrapper li:focus {
        cursor: pointer; }
      .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid .list-menu-opt-wrapper li:last-child {
        padding-bottom: 0; }
  .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid label {
    display: block; }
    .view-projects.view-display-id-page .views-exposed-widgets .views-widget-filter-field_project_category_tid label h1 {
      letter-spacing: -0.01em;
      float: left;
      margin-bottom: 0;
      max-width: 512px;
      width: 100%;
      margin-right: 32px; }

.view-projects.view-display-id-page .views-exposed-widget {
  float: none;
  padding: 0; }

.view-projects.view-display-id-page .project-single-line {
  margin-bottom: 150px; }
  .view-projects.view-display-id-page .project-single-line .node {
    width: 66%;
    margin: 0 auto; }

.view-projects.view-display-id-page .project-duo-line {
  margin-bottom: 150px; }
  .view-projects.view-display-id-page .project-duo-line:after {
    content: "";
    display: table;
    clear: both; }
  .view-projects.view-display-id-page .project-duo-line .node {
    width: 49%;
    margin-right: 2%;
    float: left; }
    .view-projects.view-display-id-page .project-duo-line .node:last-child {
      margin-right: 0; }

.view-projects.view-display-id-page .views-row:last-child .project-single-line,
.view-projects.view-display-id-page .views-row:last-child .project-duo-line {
  margin-bottom: 0; }

.view-team-lineup {
  max-width: 1328px;
  margin: 0 auto; }
  .view-team-lineup:after {
    content: "";
    display: table;
    clear: both; }
  .view-team-lineup .views-row {
    width: 48.79518%;
    margin-right: 2.40964%;
    float: left; }
    .view-team-lineup .views-row:nth-child(2n) {
      margin-right: 0;
      margin-bottom: 100px; }
    .view-team-lineup .views-row:nth-child(2n+1) {
      clear: both; }
    .view-team-lineup .views-row:last-child {
      margin-bottom: 0; }

.artist-lineup-block:after {
  content: "";
  display: table;
  clear: both; }

.artist-lineup-block .views-row {
  width: 83%;
  margin-bottom: 150px;
  transition: all 0.6s ease-out; }
  .artist-lineup-block .views-row-odd {
    float: left;
    margin-right: 17%;
    opacity: 0;
    transform: translateX(5%); }
  .artist-lineup-block .views-row-even {
    float: right;
    margin-left: 17%;
    opacity: 0;
    transform: translateX(-5%); }
  .artist-lineup-block .views-row.anim-loaded {
    opacity: 1;
    transform: translateX(0); }
  .artist-lineup-block .views-row-last {
    margin-bottom: 0; }

.group-office-center {
  line-height: 1.4; }
  .group-office-center .custom-title {
    margin-bottom: 40px;
    font-weight: 700; }
  .group-office-center .group-office-contact {
    margin-top: 40px; }

.switcher-main:after {
  content: "";
  display: table;
  clear: both; }

.switcher-main .field--name-field-office-footer-image {
  position: relative; }
  .switcher-main .field--name-field-office-footer-image:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    left: 0;
    top: 0; }

.switcher-main .attachment-before {
  min-width: 376px;
  float: left;
  height: calc(731px - 90px);
  position: relative; }
  .switcher-main .attachment-before .view-footer-region-switcher {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  .switcher-main .attachment-before a:link, .switcher-main .attachment-before a:visited, .switcher-main .attachment-before a:active {
    text-decoration: none;
    color: #adadad;
    font-weight: 700; }
  .switcher-main .attachment-before a:hover, .switcher-main .attachment-before a:focus {
    color: #000000;
    text-decoration: none; }
  .switcher-main .attachment-before .active-region a {
    color: #000000;
    font-weight: 700; }
  .switcher-main .attachment-before .block__title {
    margin-bottom: 30px;
    font-size: 0.72727rem;
    font-weight: 400; }
  .switcher-main .attachment-before .views-row {
    margin-bottom: 30px;
    font-size: 1.18181rem; }

.switcher-main > .view-content {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-left: 408px; }
  .switcher-main > .view-content .views-row {
    display: none; }
    .switcher-main > .view-content .views-row.animate-show {
      display: block; }
  .switcher-main > .view-content .views-row-first {
    display: block; }
  .switcher-main > .view-content img {
    display: block; }

.l-header-wrapper {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 200; }

/*@keyframes slide-down {
  0% { transform: scaleY(0);}
  100% { transform: scaleY(1);}
}*/
.page-node-41 .field--name-field-page-section-body .read-more-wrapper {
  margin-top: -0.6em; }

.cat-wrap:after {
  content: "";
  display: table;
  clear: both; }

.cat-wrap .select-cat-menu,
.cat-wrap .read-more {
  float: left;
  margin-right: 32px; }

.cat-wrap .read-more {
  background-color: #d6d6d6; }

.cat-wrap .active-catab {
  background-color: #f2de0d; }

.cat-wrap .cat-name:last-child {
  margin-right: 0; }

.cat-wrap .cat-name:hover, .cat-wrap .cat-name:focus {
  cursor: pointer;
  background-color: #000000;
  color: #FFFFFF; }

.cat-wrap .select-cat-menu {
  margin-top: 10px; }

.cat-content {
  margin-top: 120px; }
  .cat-content .cat-item {
    display: none; }
    .cat-content .cat-item:first-child {
      display: block; }
  .cat-content .field--name-field-faq .field-collection-view {
    margin-bottom: 80px; }
  .cat-content .field--name-field-faq .field-collection-view-final {
    margin-bottom: 0; }
  .cat-content .field--name-field-faq-question {
    font-weight: 700;
    font-size: 1.18181rem;
    margin-bottom: 20px; }
  .cat-content .field--name-field-faq-answer {
    font-size: 1.63636rem;
    line-height: 1.44; }

body.disable-scrolling, body.overlay-menu-open {
  overflow: hidden; }

.special-list .page-section-title {
  margin-bottom: 0; }

.special-list .group-text-section:after {
  content: "";
  display: table;
  clear: both; }

.special-list .field--name-field-page-section-title {
  float: left;
  width: 40.5%;
  margin-right: 2%;
  margin-bottom: 0; }
  .special-list .field--name-field-page-section-title .field__items {
    max-width: 376px; }

.special-list .field--name-field-page-section-body {
  float: left;
  width: 57.5%; }
  .special-list .field--name-field-page-section-body ul {
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 0; }
    .special-list .field--name-field-page-section-body ul li {
      position: relative;
      transition: all 0.3s ease-in-out;
      list-style: none;
      border-bottom: 1px solid #cbcbcb;
      padding: 50px 0; }
      .special-list .field--name-field-page-section-body ul li:before {
        content: "";
        position: absolute;
        width: 5px;
        margin: 50px 0;
        top: 0;
        left: 0;
        bottom: 0;
        transform: scaleY(0);
        background-color: #f2de0d;
        transition: all 0.3s ease-in-out; }
      .special-list .field--name-field-page-section-body ul li.highlighted-slist {
        padding-left: 30px; }
        .special-list .field--name-field-page-section-body ul li.highlighted-slist:before {
          transform: scaleY(1); }
      .special-list .field--name-field-page-section-body ul li:first-child {
        padding-top: 0; }
        .special-list .field--name-field-page-section-body ul li:first-child:before {
          margin-top: 0; }
      .special-list .field--name-field-page-section-body ul li:last-child {
        border-bottom: 0;
        padding-bottom: 0; }
        .special-list .field--name-field-page-section-body ul li:last-child:before {
          margin-bottom: 0; }

h1,
.block__title {
  font-weight: 700;
  font-size: 2.54545rem;
  margin-bottom: 50px;
  line-height: 1.1; }

.field--name-field-image img,
.field--name-field-video img {
  display: block; }

.field--name-field-video {
  position: relative; }

.block--views-artists-projects-block {
  max-width: 83%;
  margin: 0 auto;
  margin-top: 100px; }
  .block--views-artists-projects-block:after {
    content: "";
    display: table;
    clear: both; }
  .block--views-artists-projects-block .custom-title {
    font-size: 1.18181rem;
    font-weight: 700;
    line-height: 1.4; }
  .block--views-artists-projects-block .group-meta {
    margin-top: 20px; }
  .block--views-artists-projects-block .node__content {
    text-transform: uppercase; }
  .block--views-artists-projects-block .views-row {
    width: 48.79518%;
    margin-right: 2.40964%;
    float: left; }
    .block--views-artists-projects-block .views-row:nth-child(2n) {
      margin-right: 0;
      float: right;
      margin-bottom: 100px; }
    .block--views-artists-projects-block .views-row:nth-child(2n+1) {
      clear: both; }
    .block--views-artists-projects-block .views-row:last-child {
      margin-bottom: 0; }

#block-gtweaks-project-previous-next {
  margin-bottom: 0; }

.field-artist-quote {
  max-width: 83%;
  margin: 100px auto 0 auto;
  padding: 100px 0;
  border-top: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
  position: relative; }
  .field-artist-quote.artist-quote-empty {
    padding: 0;
    border-top: none;
    border-bottom: none;
    margin-top: 0; }
  .field-artist-quote.artist-image-empty .field--name-field-quote-text {
    width: 100%;
    float: none; }
    .field-artist-quote.artist-image-empty .field--name-field-quote-text .field__items {
      position: static;
      top: 50%;
      left: auto;
      transform: none; }
  .field-artist-quote .entity-field-collection-item:after {
    content: "";
    display: table;
    clear: both; }
  .field-artist-quote .field--name-field-image {
    width: 48.79518%;
    float: left;
    margin-right: 12.6506%; }
  .field-artist-quote .artist-name-quote {
    position: relative;
    margin-top: 30px;
    font-size: 0.81818rem;
    position: relative;
    padding-left: 60px; }
    .field-artist-quote .artist-name-quote:before {
      content: "";
      display: block;
      width: 50px;
      height: 1px;
      background-color: #000000;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0; }
  .field-artist-quote .field--name-field-quote-text {
    width: 38.55422%;
    float: left;
    font-size: 1.63636rem; }
    .field-artist-quote .field--name-field-quote-text .field__items {
      position: absolute;
      top: 50%;
      left: auto;
      transform: translateY(-50%); }

.featured-project-sp:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); }

/*html{
  overflow: hidden;
  height: 100%;
}
body{
  overflow: auto;
  height: 100%;
}*/
.featured-sect {
  margin-bottom: 150px; }
  .featured-sect .fsec-title {
    max-width: 1600px;
    margin: 0 auto;
    font-size: 2.54545rem;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 50px; }

.node--project--teaser .project-duo-line .field--name-field-sound {
  max-height: 450px; }

.node--project--teaser .group-meta {
  margin: 30px 0;
  min-height: 92px; }
  .node--project--teaser .group-meta:after {
    content: "";
    display: table;
    clear: both; }
  .node--project--teaser .group-meta .field {
    font-size: 1.18181rem;
    width: 47.95918%;
    margin-right: 4.08163%;
    float: left; }
    .node--project--teaser .group-meta .field:last-child {
      margin-right: 0; }
  .node--project--teaser .group-meta .custom-title {
    color: #000000;
    font-weight: 700;
    line-height: 1.1;
    margin-top: 4px; }
    .node--project--teaser .group-meta .custom-title a:link, .node--project--teaser .group-meta .custom-title a:visited, .node--project--teaser .group-meta .custom-title a:active {
      text-decoration: none;
      color: #000000;
      font-weight: 700; }
    .node--project--teaser .group-meta .custom-title a:hover, .node--project--teaser .group-meta .custom-title a:focus {
      color: #000000;
      text-decoration: none; }

.node--project--featured {
  position: relative;
  overflow: hidden; }
  .node--project--featured .group-meta {
    transition: all 1s ease-out;
    margin-bottom: -200px;
    opacity: 0; }
    .node--project--featured .group-meta.slide-meta {
      margin-bottom: 0;
      opacity: 1; }
  .node--project--featured .field--name-field-video {
    position: static; }
  .node--project--featured .field--name-field-project-brand {
    line-height: 1.2; }
  .node--project--featured .custom-title {
    font-size: 2.54545rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 30px; }
  .node--project--featured .read-more {
    margin-top: 70px; }
  .node--project--featured .alt-read .read-more {
    margin-top: 20px;
    background-color: #FFFFFF; }
    .node--project--featured .alt-read .read-more:hover, .node--project--featured .alt-read .read-more:focus {
      background-color: #000000;
      color: #FFFFFF; }
  .node--project--featured .vidplay {
    z-index: 20; }
    .node--project--featured .vidplay:before, .node--project--featured .vidplay:after {
      z-index: 20; }
  .node--project--featured .featured-project-sp {
    position: relative;
    display: block;
    height: 100vh;
    max-width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    z-index: 1;
    min-height: 600px; }
  .node--project--featured .group-meta-wrapper {
    max-width: 1600px;
    margin: 0 auto;
    z-index: 10;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .node--project--featured .group-meta-wrapper:hover, .node--project--featured .group-meta-wrapper:focus {
      cursor: pointer; }
  .node--project--featured .vidplay:hover, .node--project--featured .vidplay:focus {
    cursor: pointer; }
  .node--project--featured .group-meta {
    color: #FFFFFF;
    max-width: 512px; }
    .node--project--featured .group-meta:hover, .node--project--featured .group-meta:focus {
      cursor: pointer; }

.node--project--full h1 {
  margin-bottom: 0; }

.node--project--full .group-left-right-wrapper {
  margin-bottom: 50px;
  font-size: 1.63636rem; }
  .node--project--full .group-left-right-wrapper:after {
    content: "";
    display: table;
    clear: both; }

.node--project--full .field--name-field-project-release-date {
  font-size: 2.54545rem;
  font-weight: 700;
  line-height: 1.1; }

.node--project--full .field {
  margin-bottom: 50px; }
  .node--project--full .field.field--name-field-video {
    margin-bottom: 100px; }
    .node--project--full .field.field--name-field-video:last-child {
      margin-bottom: 0; }
  .node--project--full .field:last-child {
    margin-bottom: 0; }

.node--project--full .custom-title {
  line-height: 1.1; }

.node--project--full .group-left-side {
  float: left;
  width: 40.5%;
  margin-right: 19%; }

.node--project--full .group-optional-right-side {
  float: left;
  width: 40.5%; }

.node--project .field .field__label {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2;
  padding-bottom: 4px; }

.node--artist .read-more-arrow {
  height: 50px;
  width: 180px;
  position: relative;
  left: -29px;
  background-color: transparent;
  background-image: linear-gradient(to right, #000000, #000000 50%, transparent 50%, transparent);
  background-size: 202% 100%;
  background-repeat: no-repeat;
  background-position: 100% 0;
  transition: background-position 0.25s ease-in-out; }
  .node--artist .read-more-arrow:hover, .node--artist .read-more-arrow:active {
    background-color: transparent;
    background-position: 0 0; }

.node--artist--full .artist-text-wrapper .field--name-field-image {
  margin: 100px 0; }

.node--artist--full .artist-text-reg {
  max-width: 920px;
  font-size: 1.63636rem; }

.node--artist--teaser {
  position: relative; }
  .node--artist--teaser .field--name-field-image a:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3); }
  .node--artist--teaser .group-artist-teaser-meta-wrapper {
    color: #FFFFFF; }
    .node--artist--teaser .group-artist-teaser-meta-wrapper .field--name-field-artist-job-description {
      margin-top: 3px; }
    .node--artist--teaser .group-artist-teaser-meta-wrapper .group-artist-teaser-meta {
      color: #FFFFFF;
      text-decoration: none;
      display: block;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      width: 59.03614%;
      z-index: 10; }
      .node--artist--teaser .group-artist-teaser-meta-wrapper .group-artist-teaser-meta:after {
        content: "";
        display: table;
        clear: both; }
    .node--artist--teaser .group-artist-teaser-meta-wrapper .custom-title {
      color: #FFFFFF;
      font-weight: 700;
      font-size: 2.54545rem;
      line-height: 1; }
      .node--artist--teaser .group-artist-teaser-meta-wrapper .custom-title a:link, .node--artist--teaser .group-artist-teaser-meta-wrapper .custom-title a:visited, .node--artist--teaser .group-artist-teaser-meta-wrapper .custom-title a:active {
        text-decoration: none;
        color: #FFFFFF;
        font-weight: 700; }
      .node--artist--teaser .group-artist-teaser-meta-wrapper .custom-title a:hover, .node--artist--teaser .group-artist-teaser-meta-wrapper .custom-title a:focus {
        color: #FFFFFF;
        text-decoration: none; }

.node--office--teaser {
  position: relative;
  color: #FFFFFF; }
  .node--office--teaser a:link, .node--office--teaser a:visited, .node--office--teaser a:active {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 400; }
  .node--office--teaser a:hover, .node--office--teaser a:focus {
    color: #FFFFFF;
    text-decoration: none; }
  .node--office--teaser .group-office-center {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%); }

.node--office--full a:link, .node--office--full a:visited, .node--office--full a:active {
  text-decoration: none;
  color: #000000;
  font-weight: 400; }

.node--office--full a:hover, .node--office--full a:focus {
  color: #000000;
  text-decoration: none; }

.node--office--full .read-more-wrapper .read-more {
  color: #000000;
  background-color: #FFFFFF;
  margin-top: 50px; }
  .node--office--full .read-more-wrapper .read-more:hover, .node--office--full .read-more-wrapper .read-more:focus {
    background-color: #000000;
    color: #FFFFFF; }

.node--office--full .group-office-contact-full {
  margin-top: 50px; }

.node--office--full .custom-title,
.node--office--full .group-office-contact-full > div > span {
  font-weight: 700;
  font-size: 1.18181rem;
  line-height: 1.4; }

.node--team--teaser .custom-title {
  text-transform: uppercase; }

.node--team--teaser .field--name-field-team-job-description {
  font-size: 0.81818rem; }

.node--team--teaser .group-team-desc {
  margin-top: 20px; }

.node--team--teaser .custom-title {
  margin-bottom: 4px; }

.node--team--teaser .field--name-field-team-job-description {
  letter-spacing: 0.06em; }

.node--team--teaser .field--name-field-region .field__item {
  position: relative;
  padding-left: 60px; }
  .node--team--teaser .field--name-field-region .field__item:before {
    content: "";
    display: block;
    width: 50px;
    height: 1px;
    background-color: #000000;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0; }

.node--team--teaser .group-team-social {
  margin-top: 40px; }
  .node--team--teaser .group-team-social a:link, .node--team--teaser .group-team-social a:visited, .node--team--teaser .group-team-social a:active {
    text-decoration: none;
    color: #000000;
    font-weight: 400; }
  .node--team--teaser .group-team-social a:hover, .node--team--teaser .group-team-social a:focus {
    color: #000000;
    text-decoration: none; }
  .node--team--teaser .group-team-social .social-wrap a {
    display: inline-block; }
    .node--team--teaser .group-team-social .social-wrap a:hover .text {
      opacity: 1;
      transform: translateX(0); }
  .node--team--teaser .group-team-social .linkedin-c {
    height: 30px;
    line-height: 30px; }
    .node--team--teaser .group-team-social .linkedin-c:after {
      content: "";
      display: table;
      clear: both; }
    .node--team--teaser .group-team-social .linkedin-c .icon {
      background-image: url(../images/sprites/build/sprites.png?v=1602686616975);
      background-position: 0px -280px;
      width: 30px;
      height: 30px;
      display: block;
      margin-right: 15px;
      float: left; }
    .node--team--teaser .group-team-social .linkedin-c .text {
      display: inline-block;
      transition: all 0.3s ease-out;
      opacity: 0;
      transform: translateX(-8px);
      vertical-align: middle; }

.node--team--teaser .field--name-field-region {
  margin-bottom: 7px; }

.project-single-line .node--project .group-meta .field {
  font-size: 1.18181rem;
  width: 35.60606%;
  margin-right: 3.0303%;
  float: left; }
  .project-single-line .node--project .group-meta .field:last-child {
    margin-right: 0; }

.arrow-wrapper--left {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 20; }
  .arrow-wrapper--left:hover, .arrow-wrapper--left:focus {
    cursor: url("../images/slick-arrow-left.png"), auto; }

.arrow-wrapper--right {
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 20; }
  .arrow-wrapper--right:hover, .arrow-wrapper--right:focus {
    cursor: url("../images/slick-arrow-right.png"), auto; }
