.selectboxit-text {
  padding-right: 5px;
}

.selectboxit-container {
  width: 100%;
  max-width: 100%;

  .selectboxit-option-anchor {
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    display: block;
  }

  * {
    font-family: $mada, sans-serif;
    font-size: $base;
    font-weight: 400;
    white-space: nowrap;
  }

  .selectboxit-list {
    background-color: $form;
    border: none;
  }

  .selectboxit-options {
    border-radius: 0;
    max-width: 100%;

    /* Set's the drop down options width to the same width as the drop down button */
  //  max-width: 276px;

    /* Set's the max-height property to only show a subset of the drop down items.
       If you do not set a max-height property, SelectBoxIt will dynamically
       position the dropdown (when opened) to make sure the drop down items are not
       displayed outside of the current window viewport.
    */
    max-height: 260px;

    .selectboxit-option-anchor {
      border-radius: 0;
    }
  }

  span, .selectboxit-options a {
    border-radius: 0;
    text-align: left;
    height: 45px;
    border: none;
    line-height: 45px;
    display: block;
    color: black;
    transition: none;
    font-family: $mada, sans-serif;
    font-weight: 400;
    font-size: $slight;
  }

  .selectboxit-text {
    max-width: 88% !important;
  }


  .selectboxit {
   // min-width: 276px;
    width: 100%;
    border-radius: 0;
    background: $form;
    border: none;
    float: none;
    height: 45px;
    //  margin-top: -3px;

    &:hover, &:focus {
      color: black;
      background: $form;
    }
  }
}

.selectboxit .selectboxit-arrow-container {
  .selectboxit-arrow {
    margin-top: -2px;
  }
}

.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  color: $black;
  background: $yellow;
}

.selectboxit-default-arrow {
  width: 0;
  height: 0;
  border-top: 6px solid $black;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

